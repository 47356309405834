import { createStore } from 'vuex'
const store = createStore({
    state () {
      return {
        users : null,
        newsDetail : [],
      }
    },
    mutations: {
      storeUserInfo (state , payload) {
        state.users = payload
      },
      storeNewsDetail (state , payload){
        state.newsDetail = payload
    }
    },
    
  })

  export default store