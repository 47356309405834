<template>
    <div class="text-center" v-if="current_tab != 'login' && current_tab != 'register' && current_tab != 'splashScreen'">
        <nav class="mobile-bottom-nav pb-5" id="mobile-bottom-nav">
            <div class="col-3 pb-3">
                <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
                    <div class="mobile-bottom-nav__item-content">
                        
                        <router-link to="/"  id="router">
                            <!-- <fa id="fai" :icon="['fas', 'home']" /> -->
                            <!-- <img v-if="current_tab != 'home'" src="../assets/navbar/home.png" width="22"> -->
                            <!-- <img v-else src="../assets/navbar/fnicon1.png" width="22"> -->
                            <span class="" :style="current_tab == 'home' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">
                                <!-- <fa :icon="['fas', 'fa-home']"></fa> -->
                                <Iconify icon="ion-md-home" style="font-size: calc(1rem + 1.5vw) !important;" />
                            </span>
                            <!-- <p class="fat">首页</p> -->
                            <p :style="current_tab == 'home' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}" class="fat pt-0">{{$t('home')}}</p>
                        </router-link>
                        
                    </div>		
                </div>
            </div>

            <div class="col-3">
                <div class="mobile-bottom-nav__item">
                    <div class="mobile-bottom-nav__item-content">
                        
                        <router-link to="/investment" >
                            <span class="" :style="current_tab === 'investment' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">
                                <!-- <fa :icon="['fas', 'fa-shop']"></fa> -->
                                <Iconify icon="ant-design:product-filled" style="font-size: calc(1rem + 1.5vw) !important;" />

                            </span>
                            <p class="fat pt-0" :style="current_tab === 'investment' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">{{$t('product')}}</p>
                        </router-link>
                    </div>		
                </div>
            </div>

            <div class="col-3">
                <div class="mobile-bottom-nav__item">
                    <div class="mobile-bottom-nav__item-content">
                        
                        <router-link to="/myteam" >
                            <span class="" :style="current_tab === 'myteam' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">
                                <!-- <fa :icon="['fas', 'fa-shop']"></fa> -->
                                <Iconify icon="raphael:people" style="font-size: calc(1rem + 1.5vw) !important;" />

                            </span>
                            <p class="fat pt-0" :style="current_tab === 'myteam' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">{{$t('people')}}</p>
                        </router-link>
                    </div>		
                </div>
            </div>

            <div class="col-3">
                <div class="mobile-bottom-nav__item">
                    <div class="mobile-bottom-nav__item-content">
                        
                        <router-link to="/mine"  id="router">
                            <!-- <img v-if="current_tab != 'invite' && current_tab != 'home'" src="../assets/navbar/myact.png" width="22">
                            <img v-else src="../assets/navbar/fnicon4.png" width="22"> -->
                            <span class="" :style="current_tab == 'mine' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">
                                <!-- <fa :icon="['fas', 'fa-user']"></fa> -->
                                <Iconify icon="ion-md-person" style="font-size: calc(1rem + 1.5vw) !important;" />
                            </span>
                            <p class="fat pt-0" :style="current_tab == 'mine' ? {'color' : '#48c98b'} : {'color' : '#6c757d'}">{{$t('profile')}}</p>
                            <!-- <p class="fat">我的</p> -->
                        </router-link>
                    </div>		
                </div>
            </div>
        
        
        
        
        </nav>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
export default {
    

    data (){
        return {
            current_tab : 'home',
            wallet_id : 1,
        }
    },

    watch: {
        $route(to , from) {
            if(to.name == 'home'){
                if(from.name == undefined){
                    // console.log('load')
                    localStorage.setItem('popup_showed' , 'true')
                }else{
                    // console.log('navi')
                    localStorage.setItem('popup_showed' , 'false')
                }
            }
        // Extract the path from the to route and set it as current_tab
        //  let route = to.path === '/' ? 'home' : to.path.substr(1);
         this.current_tab = to.name
         
        }
    },

    

    async created(){
         const route = useRoute()
         const router = useRouter()
         await router.isReady()
        //  console.log(route)
         this.current_tab = route.name
    }
}
</script>


<style>
.mobile-bottom-nav{
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	z-index:1000;
	display:flex;	
	height:65px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
	/* box-shadow: 0 -2px 5px -2px #333; */
	background-color:#fff;
	
}
.mobile-bottom-nav__item{
    padding-top: 5px;
}
#fai{
    font-size: 20px;
    color: gray;
}
.fat{
    font-size: 12px;
    color: black;
    padding-top: 5px;
}
#router { 
    text-decoration: none;

}
</style>