<template>

    <div>
        <div class="px-3 py-5 text-center" style="background-color: #3dac78;">
            <div class="my-5 mb-3 text-white fw-semibold">
                {{$t('sign_up_description_one')}}
                <br />
                {{$t('sign_up_description_two')}}
            </div>
        </div>

        <div class="mb-4 d-flex justify-content-center">
            <div class="btn-group rounded-4 mx-0 px-0 w-100" style="margin-top: 0.2px" role="group" aria-label="Basic example" >
                <router-link to="/register" type="button" class="btn btn-light rounded-0 rounded-end fw-normal py-2" :class="[1 == 1 ? 'pill-nav-active text-white shadow me-1' : 'pill-nav-inactive']" style="font-size: 14px;">{{$t('sign_up')}}</router-link>
                <router-link to="/login" type="button" class="btn btn-light rounded-0 rounded-start fw-normal py-2" :class="[1 == 0 ? 'pill-nav-active text-white shadow ms-1' : 'pill-nav-inactive']" style="font-size: 14px;">{{$t('login')}}</router-link>
            </div>
        </div>

        <div class="my-4 mb-5 px-4">
            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('name')}}</label>
                <input type="text" class="form-control ps-5" v-model="name" :placeholder="$t('name')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="si:user-fill" />
            </div>

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('phone_number')}}</label>
                <input type="text" class="form-control ps-5" v-model="phone" :placeholder="$t('phone_number')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="tabler:phone" />
            </div>

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('password')}}</label>
                <input type="password" class="form-control ps-5" v-model="password" :placeholder="$t('password')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="mdi:password-outline" />
            </div>

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('fund_password')}}</label>
                <input type="password" class="form-control ps-5" v-model="payment_password" :placeholder="$t('fund_password')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="mdi:password-add-outline" />
            </div>

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('invitation_code')}}</label>
                <input type="text" class="form-control ps-5" v-model="invitation_code" :placeholder="$t('invitation_code')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="mdi:invite" />
            </div>

            <div class="d-flex justify-content-center">
                <button :disabled="loader ? true : false" class="btn col-12 text-white mt-2" style="background-color: #3dac78; width: 98%" @click="register()">{{$t('register')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
        </div>
    </div>
    <!-- <div id="registertopParent" >
        
        <div class="d-flex justify-content-end me-0">
            <div class="col-4">
                <select v-model="$i18n.locale" @change="lng_change($i18n.locale)" class="rounded" style="padding: 5px 15px;font-size: 16px;">
                    <option
                    v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`"
                    :value="locale"
                    >
                    {{ locale == 'hn' ? 'Hindi' : 'English' }} 
                    </option>
                </select>
            </div>
        </div>

        <div class="card border-0 bg-dark w-100 rounded-0" style="position : absolute ; bottom : 0">
         <div class="text-center mb-3">
             <img src="../assets/auth/logo2.png" class="img-thumbnail img-fluid rounded mt-5" width="100">
         </div>
         <p class="text-center text-white">{{$t('register')}}</p>
             <div class="card-body">
                 <div class="input-group mb-3">
                  <span class="input-group-text bg-white border-end-0" >{{$t('phone_number')}}</span>
                  <input v-model="phone" type="text" class="form-control border-start-0" >
                 </div>
                 <div class="input-group mb-3">
                  <span class="input-group-text bg-white border-end-0" >{{$t('name')}}</span>
                  <input v-model="name" type="text" class="form-control border-start-0">
                 </div>
                 <div class="input-group mb-3">
                  <span class="input-group-text bg-white border-end-0" >{{$t('password')}}</span>
                  <input v-model="password" type="password" class="form-control border-start-0">
                 </div>
                 <div class="input-group mb-3">
                  <span class="input-group-text bg-white border-end-0" >{{$t('fund_password')}}</span>
                  <input v-model="payment_password" type="password" class="form-control border-start-0">
                 </div>
                 <div class="input-group mb-3">
                  <span class="input-group-text bg-white border-end-0" >{{$t('invitation_code')}}</span>
                  <input v-model="invitation_code" type="text" class="form-control border-start-0">
                 </div>
             </div>

             <div class="p-3">
                <button :disabled="loader ? true : false" class="btn col-12 text-white" @click="register()" style="background-color: #E25215;">{{$t('register')}}

                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
                </div>
        
                <p class="text-center text-white">{{$t('already_account')}}?
                    <router-link to="/login"><span>{{$t('login')}}</span></router-link>
                </p>
        </div>
 
        
        
    </div> -->
</template>
 
 <script>
import { showSuccessToast , showFailToast } from 'vant';

    export default {
        data (){
            return {
                images_types : ['image/png' , 'image/jpg' , 'image/jpeg'],
                formData : new FormData(),
                phone : null,
                name : null,
                password : null,
                payment_password : null,
                invitation_code : null,
                loader : false,
                profile_preview : null,
                ipAddress : null,
            }
        },
        
        methods : {
          lng_change(lng){
            let ln = lng == 'en' ? 'English' : 'Hindi'
            localStorage.setItem('ai_app_lng' , lng)
            showSuccessToast('Language changed to '+ln )
           },
            removeImage(){
                this.profile_preview = null
            },
            verifyImage(e){
            
            const file = e.target.files[0]
            let size   = file.size / (1024 * 1024);
            let actual_size = Number(size.toFixed(1))
            if(actual_size > 6){
                this.$notify({ text: '上传照片失败',type: "error",});
                return;
            }
            if(this.images_types.includes(file.type)){
                 let link  = URL.createObjectURL(file)
                    this.profile_preview = link
                    this.formData.append('profile', file);
            }else{
                this.$notify({ text: '上传照片失败',type: "error",});
            }
        },

            register(){

                let point = this
               
                    if(this.phone != null && this.name != null && this.password != null && this.invitation_code != null){
                        if(!isNaN(this.phone) && this.phone.length >= 10 && this.phone.length <= 11){
                            this.formData.append('invitation_code', this.invitation_code);
                            this.formData.append('membership_name', this.name);
                            this.formData.append('phone', this.phone);
                            this.formData.append('login_password', this.password);
                            this.formData.append('payment_password', this.payment_password);
                            this.formData.append('ip_address', this.ipAddress);
                            this.loader = true
                            this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/register',
                            this.formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    }
                                }
                        ).then(function(response){
                            console.log(response.data)
                        if(response.data.status_code == 200){
                            showSuccessToast(point.$t(response.data.message));

                            // point.$notify({ text: response.data.message,type: "",});
                            localStorage.setItem('china_app_access_token' , JSON.stringify(response.data.data))
                            setTimeout(()=>{
                                point.$router.push('/login')
                            },1000)
                        }else{
                            point.loader = false
                            showFailToast(point.$t(response.data.message));

                            // point.$notify({ text: response.data.message,type: "error",});
                        }
                        })
                        }else{
                            showFailToast(point.$t('wrong_phone_number'));
                            // this.$notify({ text: '请提供有效的电话号码',type: "error",});
                         }
                        
                    }else{
                        showFailToast(this.$t('missing_fields'))
                        // this.$notify({ text: '缺少必填字段',type: "error",});
                    }
                
            },
            fetchIpAddress() {
                let point = this;
                // Use ipify API to get the client's public IP address
                point.axios.get('https://api.ipify.org?format=json')
                    .then(response => {
                    point.ipAddress = response.data.ip;
                    })
                    .catch(error => {
                    console.error('Error fetching IP address:', error);
                    });
                },
        },
        created(){
            this.fetchIpAddress()
           if(this.$route.params){
            this.invitation_code = this.$route.params.code


           }
        }
    }
 </script>
 
<style>
</style>
 