import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import VueAxios from 'vue-axios'
import axios from 'axios'
import Notifications from '@kyvg/vue3-notification'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Tab, Tabs } from 'vant';
import { Popup } from 'vant';
import {Cell , Field, CellGroup } from 'vant';
import i18n from './i18n'
import { Calendar } from 'vant';
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import { Divider } from 'vant';
import { Step, Steps } from 'vant';
import { Icon as Iconify } from '@iconify/vue';

// import VueVideoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'
import { Swipe, SwipeItem, PullRefresh } from 'vant';
Locale.use('en-US', enUS);

library.add(fas);
library.add(fab);
library.add(far);
import store from './vuex/store.js'
import 'vant/lib/index.css';
import { Icon } from 'vant';
import { Badge } from 'vant';
import moment from 'moment';

const app = createApp(App)
.component("fa", FontAwesomeIcon)
.component("Iconify", Iconify)
.use(VueAxios, axios)
.use(router)
.use(VueSweetalert2)
.use(Notifications)
.use(store)
.use(Icon)
.use(Tab)
.use(Tabs)
.use(Popup)
.use(Field)
.use(Cell)
.use(CellGroup)
.use(i18n)
.use(Calendar)
.use(Badge)
.use(Swipe)
.use(SwipeItem)
.use(Divider)
.use(Step)
.use(Steps)
.use(PullRefresh);
app.config.globalProperties.$moment = moment;
app.mount('#app')
