<template>
    <div style="margin-bottom: 7rem !important;">

        <div class="bg-image" id="wallet_head"></div>
        <div class="py-4 px-4" style="padding-top: 3rem !important;">
            
            <div class="glass_card shadow px-4">
                <div>
                    <div class="text-white">{{$t('your_wallet_balance')}}</div>
                    <div class="my-1 fs-1 fw-bold">{{balance}} ¥</div>
                </div>
                <div>
                    <div class="text-white">{{$t('name')}}</div>
                    <div class="fs-6 fw-semibold">{{this.$store.state.users.member_name}}</div>
                </div>
            </div>
        </div>

        <div class=" px-4">
            <div class="row">
                <div class="col-4">
                    <router-link to="/recharge">
                        <div class="glass_card shadow py-3 d-flex flex-column justify-content-center align-items-center m-1 text-black" style="border-radius: 8px">
                            <div class="mb-3">
                                <Iconify icon="ri:cash-line" style="font-size: calc(1.5rem + 1.5vw) !important;" />
                            </div>
                            <div class="" style="font-size: calc(0.3rem + 1.5vw) !important;">
                                {{$t('recharge')}}
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-4">
                    <router-link to="/withdraw/1">
                        <div class="glass_card shadow py-3 d-flex flex-column justify-content-center align-items-center m-1 text-black" style="border-radius: 8px">
                            <div class="mb-3">
                                <Iconify icon="bx:money-withdraw" style="font-size: calc(1.5rem + 1.5vw) !important;" />
                            </div>
                            <div class="" style="font-size: calc(0.3rem + 1.5vw) !important;">
                                {{$t('withdraw')}}
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-4">
                    <router-link to="/bank">
                        <div class="glass_card shadow py-3 d-flex flex-column justify-content-center align-items-center m-1 text-black" style="border-radius: 8px">
                            <div class="mb-3">
                                <Iconify icon="mingcute:bank-card-fill" style="font-size: calc(1.5rem + 1.5vw) !important;" />
                            </div>
                            <div class="" style="font-size: calc(0.3rem + 1.5vw) !important;">
                                {{$t('bank')}}
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="px-4 mt-4">
            <div class="d-flex justify-content-between align-tiems-center">
                <div class="fs-6 fw-semibold">
                    {{$t('transactions')}}
                </div>
                <div class="pe-2">
                    <Iconify icon="solar:calendar-outline" style="font-size: calc(1rem + 1.5vw) !important;" @click="show = true" />

                    <van-calendar style="" v-model:show="show" :min-date="minDate" :max-date="maxDate" type="range"  @confirm="dateChange" />
                </div>
            </div>
            <div class="d-flex justify-content-center my-3 mb-1">
                <div><span style="font-size:13px" class="fw-bold text-success">{{date}}</span></div>
            </div>
            <div>
                <div class="card">
                    <div class="card-body py-0">
                        <p v-if="ai_wallet_history.length == 0" class="text-success my-3 text-center">{{$t('no_records')}}</p>
                        
                        <div v-else v-for="(ai_history , i) in ai_wallet_history" :key="i">
                            <div v-if="ai_history.is_paid == 1" class="row py-3 border-bottom">
                                <div class="col-8 fw-bold text">{{ai_history.change_type}} {{ai_history.enduser_remark == null ? '' : '( '+ ai_history.enduser_remark +' )'}}</div>
                                <div class="col-4 text fw-bold text-end text-primary" v-if="ai_history.change_status == '增'"> + {{ai_history.charge_amount}} ¥</div>
                                <div class="col-4 text fw-bold text-end text-danger" v-else> - {{ai_history.charge_amount}} ¥</div>
                                <div class="col-6 text mt-2" style="font-size : 10px">{{ai_history.created_at}}</div>
                                <div class="col-6 text text-end mt-2" v-if="ai_history.is_paid == 0">{{$t('settlement_in_progress')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
    
    <!-- <div class="">
        <div class="p-3" id="detail_body">
            <div v-if="wallet_id == 1" class="mb-5">
                <div class="col-12">
                    <div class="card border-0" style="background: linear-gradient(90deg,rgba(69,69,69,1) 0%, rgba(16,16,16,1) 85%) ; height : 10rem">
                            <div class="card-body mb-0">
                                <img src="../assets/wallet/ai.png" style="">
                                <p class="text-white fw-bold  mb-0 p-0" style="position : absolute ; top : 20px ; left : 4rem">{{$t('ai_wallet')}}</p>
                                <p style="color : white ; position : absolute ; top : 5rem ; font-size : 35px ;">{{balance}} ¥</p>
                                <div id="withdraw_curve">
                                <router-link to="/withdraw/1">
                                <span id="withdraw_curve_span">{{$t('withdraw')}} <van-icon name="arrow" /> </span>
                                </router-link>
                                </div>
                                <img src="../assets/shop/arrow_forward.png" style=" position : absolute ; top : 2px ; right : 1px">
                            </div>
                    </div>
                    
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <van-cell title="Select Date Filter" class=" bg-dark text-white "   @click="show = true" >
                            <fa class="text-white" style="color : #773DF5" :icon="['fas', 'fa-calendar-days']"></fa>
                        </van-cell>
                        <span style="font-size:12px" class="fw-bold">{{date}}</span>
                        <van-calendar v-model:show="show" :min-date="minDate" :max-date="maxDate" type="range"  @confirm="dateChange" />

                    </div>
                    
                </div>

                <div class="" style="padding-bottom : 5rem">
                    <p v-if="ai_wallet_history.length == 0" class="text-danger mt-5 text-center">{{$t('no_records')}}</p>
                    <div v-else v-for="(ai_history , i) in ai_wallet_history" :key="i" class="card mt-3 border-0 shadow-sm">
                        <div class="card-body bg-dark text-white" style="padding : 10px 10px 10px 10px">
                            <div class="row">
                                <div class="col-8 fw-bold text"> {{ai_history.change_type_en}} {{ai_history.enduser_remark == null ? '' : '( '+ ai_history.enduser_remark +' )'}}</div>
                                <div class="col-4 text fw-bold text-end text-primary" v-if="ai_history.change_status == '增'"> + {{ai_history.charge_amount}} ¥</div>
                                <div class="col-4 text fw-bold text-end text-danger" v-else> - {{ai_history.charge_amount}} ¥</div>
                                <div class="col-8 text mt-2" style="font-size : 10px">{{ai_history.created_at}}</div>
                                <div class="col-4 text text-end mt-2" v-if="ai_history.is_paid == 0">settlement in progress</div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="wallet_id == 2">
                <div class="col-12 mt-3 mb-5">
                    <router-link to="/withdraw/2">
                    <div class="card border-0" style="background: linear-gradient(90deg,rgba(69,69,69,1) 0%, rgba(16,16,16,1) 85%); height : 10rem">
                        <div class="card-body mb-0">
                            <img src="../assets/wallet/ai.png" style=" ">
                            <p style="color : white ; position : absolute ; top : 5rem ; font-size : 35px"> {{crypto_balance}} $</p>
                            <img src="../assets/shop/arrow_forward.png" style=" position : absolute ; top : 2px ; right : 1px">
                            <p class="text-white fw-bold  mb-0 p-0" style="position : absolute ; top : 20px ; left : 4rem">{{$t('crypto_wallet')}}</p>
                            <div id="withdraw_curve">
                            <router-link to="/withdraw/2">
                                <span id="withdraw_curve_span">{{$t('withdraw')}} <van-icon name="arrow" /></span>
                            </router-link>

                            </div>

                        </div>
                    </div>
                    </router-link>
                    
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <van-cell title="Select Date Filter" class=" bg-dark text-white "   @click="show = true" >
                            <fa class="text-white" style="color : #773DF5" :icon="['fas', 'fa-calendar-days']"></fa>
                        </van-cell>
                        <span style="font-size:12px" class="fw-bold">{{date}}</span>
                        <van-calendar v-model:show="show" :min-date="minDate" :max-date="maxDate" type="range"  @confirm="dateChange" />

                    </div>
                    
                </div>

                <div style="padding-bottom : 5rem">
                    <p v-if="crypto_wallet_history.length == 0" class="text-danger mt-5 text-center">{{$t('no_records')}}</p>
                    <div v-else v-for="(crypto_history , i) in crypto_wallet_history" :key="i" class="card mt-3 border-0 shadow-sm">
                        <div class="card-body bg-dark text-white" style="padding : 10px 10px 10px 10px">
                            <div class="row">
                                <div class="col-8 fw-bold text">{{crypto_history.change_type_en}} {{crypto_history.enduser_remark == null ? '' : '( '+ crypto_history.enduser_remark +' )'}}</div>                                
                                <div class="col-4 text fw-bold text-end text-primary" v-if="crypto_history.change_status == '增'"> + {{crypto_history.charge_amount}} $</div>
                                <div class="col-4 text fw-bold text-end text-danger" v-else> - {{crypto_history.charge_amount}} $</div>
                                <div class="col-8 text mt-2" style="font-size : 10px">{{crypto_history.created_at}}</div>
                                <div class="col-4 text text-end mt-2" v-if="crypto_history.is_paid == 0">settlement in progress</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
    const date = ref('');
    const show = ref(false);

    // const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
    const onConfirm = () => {
    };

    const setShow = (values) => {
        const [start, end] = values;
        date.value = `${start} / ${end}`;
        show.value = false;
    };
    
    return {
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2025, 11, 31),
      date,
      show,
      onConfirm,
      setShow
    };
  },

    data (){
        return {
           balance : 0,
           // crypto_balance : 0,
           from_date : null,
           to_date : null,
           loader : false,
           wallet_id : 1,

           ai_wallet_history : [],
           crypto_wallet_history : [],
        }
    },

    watch: {
        wallet_id(new_value ){
            if(new_value == 1 && this.ai_wallet_history.length == 0){
                this.getWalletHistories(new_value)
            }

            if(new_value == 2 && this.crypto_wallet_history.length == 0){
                this.getWalletHistories(new_value)
            }
            
        }
    },

    methods : {
        getWalletHistories(wallet_id){
            let point = this
            let enduser_id = this.$store.state.users.id
            let from_date = this.from_date
            let to_date = this.to_date
            this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/financial/history',
            { enduser_id , wallet_id , from_date , to_date},
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
            ).then(function(response){
                if(wallet_id == 1){
                    point.ai_wallet_history = response.data.data
                }else{
                    point.crypto_wallet_history = response.data.data
                }
                // point.crypto_balance = response.data.crypto_balance
                // point.balance = response.data.balance
                // console.log(response.data)
                
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.removeItem('china_app_access_token')
                    location.reload()
                }
            })
        },

        formatDateToYYYYMMDD(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(date.getDate()).padStart(2, '0');
            
            return `${year}-${month}-${day}`;
        },

        dateChange(values){
            let from_date = new Date(values[0])
            let to_date = new Date(values[1])

            this.from_date = this.formatDateToYYYYMMDD(from_date);
            this.to_date = this.formatDateToYYYYMMDD(to_date);
            this.setShow([this.from_date , this.to_date]);
            if(this.wallet_id == 1){
                this.ai_wallet_history = []
            }else{
                this.crypto_wallet_history = []
            }
            this.getWalletHistories(this.wallet_id)

        }

        
    },

    created(){
        let point = this
        point.getWalletHistories(1)
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/balance',
        {'enduser_id' : enduser_id },
            {
                headers: {
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                }
            }
        ).then(function(response){
            // point.crypto_balance = response.data.crypto_balance
            point.balance = response.data.balance
            // console.log(response.data)
            
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })
                
    }
}
</script>

<style>
    #wallet_head {
        height: 310px;
        width: 100%;
        background: url('../assets/home/wallet_bg.png') no-repeat center bottom;
        background-size: cover;
        position: absolute;
        z-index: -1;
    }

    .glass_card {
        
        background: rgba( 255, 255, 255, 0.28 );
        /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
        backdrop-filter: blur( 18px );
        -webkit-backdrop-filter: blur( 18px );
        border: 1px solid rgba(251, 249, 249, 0.18);
        border-radius: 1rem;
        padding: 1.5rem;
        z-index: 10;
    }

    .header{
        background-color: #773DF5;
    }
    #detail_body{
        margin-top: 4rem;
        height : 100vh
    }
    #detail_body .input-group-text{
        font-size: 12px;
    }

    #withdraw_curve{
        width : 6rem;
        height : 2rem;
        background-color : #E25215;
        position : absolute;
        right : .9px;
        top : 7rem;
        border-radius : 15px 0 0px 15px
    }
    #withdraw_curve_span  {
        position : relative;
        left : 15px;
        top : 3px;
        font-size : 14px;
        font-family : Arial;
        color : white;
        
    }
</style>