<template>
    <div class="d-flex justify-content-center" style="height: 100vh !important;">
        <div style="width: 100% !important;">
            <img src="../assets/contactus/splash_screen.jpg" class="img-fluid" style="height: 100% !important" alt="">
            <!-- <div class="mt-3 text-center fs-1 fw-semibold" style="color: rgb(61, 172, 120)">
                Brasilagro
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods : {
        
    },

    created(){
        setTimeout(() => {
            this.$router.push('/login')
        }, 3000);
    }
   
}
</script>

<style>

#qrcard{
    position: absolute;
	bottom:1.5rem;
	width: 100%;

}


</style>