<template>
    <div class="p-4 pb-2" style="background: linear-gradient(90deg, rgba(61,172,120,.1) 0%, rgba(255,255,255,1) 100%);">
        <p class="text-center">{{$t('product')}}</p>
    </div>
    <div class="p-3">
        

        <div class="mb-3 d-flex justify-content-end" style="position:absolute ; top:20px ; right:10px">
            <router-link to="/investment/history" class="btn btn-sm btn-success" style="background-color: #3dac78; border-color: #3dac78">
                <span ><Iconify icon="mdi:clipboard-text-history-outline" style="font-size: calc(0.9rem + 1.5vw) !important;" class="" /></span>
                <!-- {{$t('history')}} -->
            </router-link>
        </div>

        <div class="shadow-sm">
            <img src="../assets/shop/product_banner.png" alt="" class="img-fluid" style="width: 100%; height: 150px">
        </div>

        <div class="d-flex gap-1 mt-3" style="width: 40rem; max-width: 100%; overflow-x: auto; overflow-y: hidden; white-space: nowrap; scroll-behavior: smooth; padding: 0.5rem; scrollbar-width: none; -ms-overflow-style: none;">
            <button v-for="(category, index) in categories" :key="index" @click="handleCategoryChange(category.id)" :class="[category.id == active_category_id ? 'pill-nav-active text-white' : 'bg-light text-dark', categories.length - 1 != index ? 'me-1' : '']" style="font-size: 13px; width: 100px;" class="btn btn-success">{{category.group_name}}</button>
        </div>

        <div class="my-4">
        <div v-for="(product, index) in products" :key="index" style="background-color : #F1F1F1" class="card shadow-sm border-0 rounded-2 mb-3">
            <div class="card-body p-0">
                <div class="">
                    <div class="p-3">
                        <div class="fw-semibold" style="font-size: 15px">{{product.name}}</div>
                        <div class="fw-semibold mt-3 m-0 row" style="color: #3dac78">
                            <!-- <span><Iconify icon="icons8:price-tag" style="font-size: calc(0.6rem + 1.5vw) !important;" class="me-1" /></span> -->
                            <div class="col-4 p-0 text-center">
                              <span style="font-size: 16px;">¥ {{product.price}} <p class="text-muted text-center" style="font-size:13px">{{$t('price')}}</p></span>
                            </div>
                            <div class="col-4 p-0">
                                <div class="fw-semibold text-center" style="font-size: 16px" v-if="product.isInterestFixedProduct == 0"> {{product.static_income}}% <p class="text-muted text-center" style="font-size:13px">{{$t('pay_back')}}</p></div>  
                                <div class="fw-semibold text-center" style="font-size: 16px" v-else>¥ {{Number(product.price) + Number(product.fixedInterest)}} <p class="text-muted text-center" style="font-size:13px">{{ $t('pay_back') }}</p></div>  
                            </div>
                            <div class="col-4 p-0">
                               <div class=" me-2 text-center" style="font-size: 16px; width: 120px" v-if="product.isInterestFixedProduct == 0">{{$t('every')}} {{product.pay_day_interval}} {{$t('days')}} <p class="text-muted text-center" style="font-size:13px">{{$t('days')}}</p></div>
                               <div class=" me-2 text-center" style="font-size: 16px; width: 120px" v-else>{{$t('after')}} {{product.pay_day_interval}} {{$t('one_time_days')}} <p class="text-muted text-center" style="font-size:13px">{{$t('days')}}</p></div>
                            </div>
                            <!-- <span class="fw-semibold text-center"  v-if="product.isInterestFixedProduct == 0"> {{product.static_income}}% {{$t('pay_back')}}</span>  -->
                        </div>
                        <div @click="productDetail(product.id)" class="col-12 btn btn-success fw-semibold mx-1" style="height:2.5rem;background-color: rgb(61, 172, 120); border-color: rgb(61, 172, 120); --bs-btn-padding-y: .2rem; --bs-btn-font-size: 1rem;">
                            <span style="position:relative ; top:4px">{{$t('buy')}} </span>
                        </div>
                    </div>
                    <!-- <div style="background-color: #3dac78; width: 80px; height: inherit; border-radius: 0px 15px 15px 0px" class="p-0 m-0 text-white"> 
                        <div class="rotate-center" style="">
                            <div class="fw-semibold text-center" style="font-size: 12px" v-if="product.isInterestFixedProduct == 0"> {{product.static_income}}% {{$t('pay_back')}}</div>  
                            <div class="fw-semibold text-center" style="font-size: 13px" v-else>¥ {{Number(product.price) + Number(product.fixedInterest)}} {{ $t('pay_back') }}</div>  
                            <div class="text-center" style="font-size: 13px; width: 120px" v-if="product.isInterestFixedProduct == 0">{{$t('every')}} {{product.pay_day_interval}} {{$t('days')}}</div>
                            <div class="text-center" style="font-size: 13px; width: 120px" v-else>{{$t('after')}} {{product.pay_day_interval}} {{$t('one_time_days')}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        
    </div>
    </div>
    
</template>

<script>
// import {showFailToast} from 'vant';


export default {
    data (){
        return {
           products : [],
           categories: [],
           active_category_id: 1,
           image_path : '' ,
        }
    },

        methods : {
        handleCategoryChange(category_id) {
            this.active_category_id = category_id;
            this.getProducts(category_id);
        },
            
        async getProducts(groupId){

            let point = this;
            let enduser_id = this.$store.state.users.id
            
            await this.axios.post(process.env.VUE_APP_BASE_URL+'/investment/product/get',
            {'enduser_id' : enduser_id, 'group_id' : groupId},
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
            ).then(function(response){
                console.log(response.data)
                point.products = response.data.data.products.products;
                point.categories = response.data.data.categories;
                
            }).catch((error)=>{
                // console.log(error)

                if(error.response.status == 401){
                    localStorage.removeItem('china_app_access_token')
                    location.reload()
                }
            })
        },

        productDetail(product_id) {
            this.$router.push(`/investment/detail/${product_id}`);
        }
    },
    created(){
        this.getProducts(this.active_category_id)
        this.image_path = process.env.VUE_APP_BASE_IMAGE_URL
        
 
    }
}
</script>

<style>
    .pill-nav-active {
        background-color: #3dac78 !important;
        border-color: #3dac78 !important;
        color: white !important;
    }

    .pill-nav-inactive {
        color: #3dac78 !important;
    }

    .rotate-center {
        transform: rotate(-90deg);
        position: absolute;
        top: 35%;
        right: -10%;
        width: 149px;
        /* height: 38px; */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    
</style>