<template>
    <div class="bg-light" style="height : 100vh">
     
     <div class="row header fixed-top">
         <div class="">
             <router-link to="/mine">
                 <fa class="text-white" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
             </router-link>
             
             <p class="text-white text-center pt-3">账单明细</p>
         </div>
        
     </div>
 
     <div class="p-3" id="detail_body">
        <div class="card mb-3">
            <div class="card-body">
                <label style="font-size : 12px">选择查询日期</label>
                <div class="row">
                    <div class="col-6">
                        <input class="form-control" v-model="fromDate" type="datetime-local">
                    </div>
                    <div class="col-6">
                        <input class="form-control" v-model="toDate" type="datetime-local">
                    </div>
                </div>
                <button class="btn col-12 text-white mt-3" @click="serach()" style="background-color: #773DF5;">查询</button>
            </div>
        </div>
         <div class="table-responsive mb-4">
            <table class="table table-bordered">
                <thead class="border">
                    <th id="th" class="text-center">日期</th>
                    <th id="th" class="text-center">变更类型</th>
                    <th id="th" class="text-center">变更金额</th>
                    <th id="th" class="text-center">币种</th>
                    <th id="th" class="text-center">相关人员</th>
                    <th id="th" class="text-center">余额</th>
                </thead>
                <tbody>
                    <tr v-for="(record , i) in records" :key="i">
                        <td id="th" class="text-center">{{ new Date(Date.parse(record.created_at)).getFullYear() +'-'+ (new Date(Date.parse(record.created_at)).getMonth() + 1) + '-' + (new Date(Date.parse(record.created_at)).getDate()) + ' ' + (new Date(Date.parse(record.created_at)).getHours()) + ':' + (new Date(Date.parse(record.created_at)).getMinutes()) + ':' + (new Date(Date.parse(record.created_at)).getSeconds())}}</td>
                        <td id="th" class="text-center">{{ record.change_type }}</td>
                        <td id="th" class="text-center">{{ record.charge_amount }}</td>
                        <td id="th" class="text-center">{{ record.currency_type }}</td>
                        <td id="th" class="text-center">{{ record.related_member_no }}</td>
                        <td id="th" class="text-center">{{Number( record.amount_after_recharge).toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
         </div>
     </div>
 
     
     </div>
 </template>
 
 <script>
 export default {
     
     data(){
        return {
            records : '',
            fromDate : '',
            toDate : '',
        }
     },

     methods:{
        serach(){
            if(this.fromDate != '' && this.toDate != ''){
                let point = this;
                let enduser_id = this.$store.state.users.id
                this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/get/all/transaction/filter',
                {'enduser_id' : enduser_id , 'from' : this.fromDate , 'to' : this.toDate},
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    console.log(response.data)
                    point.records = response.data
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })
            }
        }
     },
     created(){
        this.image_path = process.env.VUE_APP_BASE_IMAGE_URL
        let point = this;
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/get/all/transaction',
        {'enduser_id' : enduser_id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            point.records = response.data
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })
     }
 }
 </script>
 
 <style>
     .header{
         background-color: #773DF5;
     }
     #detail_body{
         margin-top: 4rem;
     }
     #detail_body .input-group-text{
         font-size: 12px;
     }
     #th{
        font-size: 12px;
     }
 </style>