<template>
    <div>
        <!-- <div class="bg-image" id="wallet_head" style="height: 240px !important;"></div> -->
        <div class="px-4" style="padding-top: 0.5rem !important;">
            <div>
                <Iconify class="" style="position : absolute ; top : 20px ; left : 30px; font-size: 17px !important; color: #3dac78;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <div class="text-center py-3 pt-2 fw-semibold fs-6" style=" color: #3dac78;">
                    {{$t('bank_account')}}
                </div>
            </div>

            <!-- <div class="glass_card shadow px-4">
                
                <div class="my-1 fs-1 fw-bold text-white">{{bank_card}}</div>
            
                <div class="fs-6 fw-semibold text-white">{{bank_name}}</div>
                
                <div class="fs-6 fw-semibold text-white">{{name}}</div>
                
            </div> -->
            
        </div>

        <div class="px-4 mt-3 mb-4">
            <img src="../assets/bank/bank.png" class="img-fluid" alt="">
        </div>

        <div class=" px-4 " style="margin-bottom: 5rem !important;">

            <div class="mb-3">
                <label class="mb-2 fw-regular">{{$t('account_number')}}</label>
                <input id="bankCard"  type="number" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <div class="mb-3">
                <label class="mb-2 fw-regular">{{$t('bank_name')}}</label>
                <input v-model="bank_name" type="text" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <div class="mb-3">
                <label class="mb-2 fw-regular">{{$t('account_holder_name')}}</label>
                <input v-model="name" type="text" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <!-- <div class="mb-4">
                <label class="mb-2 fw-regular">{{$t('address')}}</label>
                <input v-model="address" type="text" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div> -->

            <div class="">
                <button :disabled="loader ? true : false" class="btn col-12 text-white" @click="bankInfoSubmit()" style="background-color: #3dac78;">{{$t('submit')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { showSuccessToast , showFailToast } from 'vant';

export default {
    data (){
        return {
           name : "",
           bank_card : "",
           bank_name : "",
           branch : "",
           loader : false,
           account_type : '',
           address: ''
        }
    },

    methods : {
        bankInfoSubmit(){
            let point = this;
            let enduser_id = this.$store.state.users.id
            let bankcard = document.getElementById('bankCard').value;
            if(bankcard != "" && this.bank_name != "" && this.name != ""){
                if(!isNaN(bankcard) && bankcard.length >= 9 && bankcard.length <= 18){
                    this.loader = true
                    this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/withdraw/bankinfo/store',
                    {'enduser_id' : enduser_id , 'name' : this.name , 'bank_card_no' : bankcard , 'bank_name' : this.bank_name , 'branch' : this.branch , 'ifsc_code' : this.ifsc_code, 'address' : this.address},
                        {
                            headers: {
                                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                            }
                        }
                    ).then(function(response){
                        // console.log(response.data)
                    if(response.data.status_code == 200){
                        showSuccessToast(point.$t(response.data.message))
                        // point.$notify({ text: response.data.message,type: "",});
                        setTimeout(()=>{
                            location.reload()
                        },1000)
                    }else{
                        showFailToast(point.$t(response.data.message))
                        // point.$notify({ text: response.data.message,type: "error",});
                        point.loader = false
                    }
                    }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.removeItem('china_app_access_token')
                            location.reload()
                        }
                    })
                }else{
                    showFailToast(point.$t('f_bank_no'))
                    // point.$notify({ text: '请提供正确的银行账号',type: "error",});
                }
                
                
            }else{
                showFailToast(this.$t('missing_fields'))
            }
        },

        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
         if(keyCode != 8){
          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
             $event.preventDefault();
         }
         }
         
         },
    },

    created(){
        let point = this;
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/withdraw/bankinfo/get',
        {'enduser_id' : enduser_id},
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
        ).then(function(response){
            if(response.data.status_code == 200){
                point.name = response.data.data.name;
                point.bank_name = response.data.data.bank_name;
                document.getElementById('bankCard').value = response.data.data.bank_card_no;

                point.bank_card = response.data.data.bank_card_no;
                point.ifsc_code = response.data.data.ifsc_code;
                point.account_type = response.data.data.account_type;
                point.address = response.data.data.address;
            }
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })
    }
}
</script>

<style>
    /* .bank-image {

    } */

    .header{
        background-color: #773DF5;
        
    }
    #detail_body{
        margin-top: 4rem;
    }
    #detail_body .input-group-text{
        font-size: 12px;
    }
</style>