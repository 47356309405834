<template>
    <div>

        <div class="bg-image" id="wallet_head" style="height: 240px !important;"></div>
        <div class="py-4 px-4" style="padding-top: 1rem !important;">
            <div>
                <!-- <router-link to="/mine"> -->
                    <Iconify class="text-white" style="position : absolute ; top : 26px ; left : 30px; font-size: 19px !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <!-- </router-link> -->
                <div class="text-center py-3 pt-2 text-white fw-semibold fs-6">
                {{$t('withdraw')}}
            </div>
            </div>

            <div class="glass_card shadow px-4">
                <div>
                    <div class="text-white">{{$t('your_wallet_balance')}}</div>
                    <div class="my-1 fs-1 fw-bold">{{this.$store.state.users.balance}} ¥</div>
                </div>
                <div>
                    <div class="text-white">{{$t('name')}}</div>
                    <div class="fs-6 fw-semibold">{{this.$store.state.users.member_name}}</div>
                </div>
            </div>
            
        </div>
        <div class="card card-body shadow mx-4" style="border-color: #3dac78 !important; background-color: #fff !important">
            <div class="">
                <div class="fs-5 mb-2 fw-semibold">
                    <div class="">
                        <div style="color: #3dac78 !important;">
                            {{ bank_card }}
                        </div>
                    </div>
                </div>
                <div class="fs-5 my-1" style="font-size: 15px !important;">
                    <div>
                        {{ bank_name }}
                    </div>
                </div>
                <div class="fs-5" style="font-size: 15px !important;">
                    <div>
                        {{ name }}
                    </div>
                </div>
                
            </div>
            
        </div>
        <div class="mt-4 px-4 " style="margin-bottom: 5rem !important;">
            <div class="mb-3">
                <label class="mb-2 fw-semibold">{{$t('currency')}}</label>
                <select class="form-control" v-model="wallet_id" style="border: none !important; background-color: #eee !important;" @change="handleCurrencyChange">
                    <option value="1">{{$t('china')}}</option>
                    <option value="2">{{$t('usdt')}}</option>
                </select>
            </div>

            <div class="mb-4">
                <label class="mb-2 fw-semibold">{{$t('withdraw_amount')}}</label>
                <input v-model="receive_amount" type="number" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <div class="mb-4">
                <label class="mb-2 fw-semibold">{{$t('fund_password')}}</label>
                <input v-model="password" type="password" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <div class="">
                <button :disabled="loader ? true : false" class="btn col-12 text-white" @click="withdrawSubmit()" style="background-color: #3dac78;">{{$t('submit')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
        </div>
        

        <van-popup v-if="popup_showed == 1" v-model:show="show"   :style="{ width : '80%'  , 'border-radius' : '15px'}">
           <div class="card bg-white border-0">
                <!-- <div  class="border-0 d-flex justify-content-center bg-dark" style="height : 5rem ; background-color : #000000" > -->
                    <!-- <div class="bg-dark" style=" width: 70px ; height: 70px;border-radius: 50%;position : absolute ; top : 3rem"> -->
                        <!-- <img src="../assets/home/anic.png" width="55" class="rounded img-fluid" style="position : relative ; left : .6rem ; top : .5rem"> -->
                    <!-- </div> -->
               <!-- </div> -->

                <h4 class="text-center mt-2" style="" v-text="title"></h4>
                <div class="d-flex justify-content-center">
                    <div class="col-12 ps-3 pe-3">
                      <p class="cryptofs text-muted" style="font-family : Arial ; font-size : .9rem" v-html="content"></p>
                    </div>
                </div>
                <div class="d-flex justify-content-center border-0 bg-white card-footer" style="">
                    <button @click="show = false"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : #3dac78">Close</button>
                    </div>
                </div>

        </van-popup>

    </div>
    <!-- <div class="" style="height : 100vh">
    
        <div class="row  fixed-top">
            <div class="">
                <router-link to="/wallet">
                    <fa class="text-white" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
                </router-link>
                
                <p class="text-white text-center pt-3"> {{wallet_id == 1 ? 'AI Wallet' : 'Crypto Wallet'}}</p>
            </div>
        </div>

        <div class="p-3" id="">
            <div class="">
                <div class="card border-0" style="margin-top : 3.5rem ; border-radius : 20px ; background: linear-gradient(90deg,rgba(69,69,69,1) 0%, rgba(16,16,16,1) 85%);">
                    <div class="card-body">
                        


                        <h4 class="fw-bold text-white">{{name}}</h4>
                        <p class="mb-0 fw-bold text-white" style="position : relative ; top : 0rem">{{bank_name}}</p>
                        <p class="mb-0 fw-bold text-white" style="position : relative ; top : .3rem ; font-size : 13px">{{ifsc_code}}</p>
                        <p class="mb-0 fw-bold text-white" style="position : relative ; top : .6rem ; font-size : 13px">{{account_type}}</p>
                        <p id="bankCardc"  class="fw-bold text-white" style="position : relative ; top : 1rem ; font-size : 14px">{{bank_card}}</p>
                    </div>
                </div>
            </div>

            <div class="card mt-3 border-0 bg-dark">
                <div class="card-body">

                    <label class="text-white">{{$t('withdraw_amount')}}</label>
                    <div class="input-group mb-3 mt-2">
                        <span class="input-group-text bg-white border-end-0" >{{$t('withdraw_amount')}}</span>
                        <input v-model="receive_amount" type="number" class="form-control border-start-0">
                    </div>
                    <p class="text-danger" style="position : relative ; bottom : .6rem ; font-size : 12px">Your balance - {{balance}} {{this.$route.params.wallet_id == 1 ? '¥' : '$'}}</p>

                    <label class="text-white">{{$t('fund_password')}}</label>
                    <div class="input-group mb-3 mt-2">
                        <span class="input-group-text bg-white border-end-0" >{{$t('fund_password')}}</span>
                        <input v-model="password"  autocomplete="off" type="password" class="form-control border-start-0" id="basic-url" aria-describedby="basic-addon3">
                    </div>

                </div>
                <div class="p-3 ">
                    <button :disabled="loader ? true : false" class="btn col-12 text-white" @click="withdrawSubmit()" style="background-color: #E25215;">{{$t('submit')}}
                        <div class="lds-dual-ring" v-if="loader"></div>
                    </button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import {  showSuccessToast , showFailToast} from 'vant';
import { ref } from 'vue';

export default {
    setup() {
        const show = ref(false);
        
        const showPopup = () => {
          show.value = true;
        };

        
        return {
           show,
           showPopup,

        };
    },
    data (){
        return {
            balance : '',
            name : null,
            bank_card : null,
            bank_name : null,
            branch : null,
            password : null,
            receive_amount : null,
            loader : false,
            wallet_id : 1,
            ifsc_code : '',
            account_type : '',
            ipAddress : null,
            popup_showed : 0,
            content: null,
            title: null
        }
    },

    methods : {

        handleCurrencyChange(){
            // let currency = e.target.value;
            if (this.wallet_id == 2 && this.title != null) {
                this.popup_showed = 1;
                this.show = true;
            }
        },
        withdrawSubmit(){
            let point = this;
            let enduser_id = this.$store.state.users.id
            if(this.name != null && this.bank_card != null && this.bank_name != null  && this.password != null && this.receive_amount != null){
                if(!isNaN(this.receive_amount)){
                    if(this.receive_amount >= 100){
                        this.loader = true
                        this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/withdraw',
                        {'enduser_id' : enduser_id , 'withdraw_amount' : this.receive_amount , 'password' : this.password , 'wallet_id' : this.wallet_id  , 'ip_address' : this.ipAddress},
                            {
                                headers: {
                                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                }
                            }
                        ).then(function(response){
                            console.log(response.data)
                        if(response.data.status_code == 200){
                            showSuccessToast(point.$t(response.data.message))

                            setTimeout(()=>{
                                location.reload();
                            },1000)

                        }else{
                            point.loader = false
                            showFailToast(point.$t(response.data.message))

                        }
                        }).catch((error)=>{
                            if(error.response.status == 401){
                                localStorage.removeItem('china_app_access_token')
                                location.reload()
                            }
                        })
                    }else{
                        showFailToast(this.$t('max_withdraw'))

                    }
                    
                }
               
            }else{
                showFailToast(this.$t('pls_add_bank_info'))
            }
        },

        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
         if(keyCode != 8){
          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
             $event.preventDefault();
         }
         }
         
         },
         fetchIpAddress() {
            let point = this;
            // Use ipify API to get the client's public IP address
            point.axios.get('https://api.ipify.org?format=json')
                .then(response => {
                point.ipAddress = response.data.ip;
                })
                .catch(error => {
                console.error('Error fetching IP address:', error);
                });
            },
    },

    created(){

        let point = this;
        point.fetchIpAddress()
        point.wallet_id = point.$route.params.wallet_id
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/withdraw/bankinfo/get',
        {'enduser_id' : enduser_id ,'name' : this.name , 'bank_card' : this.bank_card , 'bank_name' : this.bank_name , 'branch' : this.branch , 'wallet_id' : this.$route.params.wallet_id },
            {
                headers: {
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                }
            }
        ).then(function(response){
            console.log(response.data)
            if(response.data.status_code == 200){
              point.name = response.data.data.name;
              point.bank_card = response.data.data.bank_card_no;
              point.bank_name = response.data.data.bank_name;
              point.branch = response.data.data.bank_name;
              point.ifsc_code = response.data.data.ifsc_code;
              point.account_type = response.data.data.account_type;
              point.balance = Number(response.data.data.balance).toFixed(2) ;
            }
        }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })

        this.axios.post(process.env.VUE_APP_BASE_URL+'/popup',
        {'enduser_id' : enduser_id, 'id' : 2},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            // console.log(response)
            point.content = response.data[0]?.content
            point.title   = response.data[0]?.title
            point.show = response.data[0]?.status == 1 ? true : false
        })
                
    }
}
</script>

<style>
    .header{
        background-color: #773DF5;
    }
    #detail_body{
        margin-top: 4rem;
    }
    .small_ts{
        font-size: 12px;
    }
    #detail_body .input-group-text{
        font-size: 12px;
        background-color: #773DF5;

    }
    .input-group{
        /* border : 1px solid #773DF5;
        border-radius : 5.5px */
        /* background-color: #773DF5; */
    }
</style>