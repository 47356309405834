<template>
   <div class="">
    
    <div class="row fixed-top">
        <div class="" style="background-color : #000000">
            <router-link to="/spinner">
                <fa class="text-white" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
            </router-link>
            
            <p class="text-white text-center pt-3">  {{$t('spin_history')}}  </p>
            <!-- <div id="divider"></div> -->
        </div>
       
    </div>

    <div class="p-3" id="detail_body">
            
            <!-- <div class="bg-dark " style="background-color : rgb(228, 228, 228) ; padding : 2px ; border-radius : 6px ">
                <button type="button" @click="group_id = 1" class="btn col-6 text-white  btn-sm" :style="group_id == 1 ? {'border-radius' : '6px' , 'background-color' : 'black'} : ''" style="font-size : 13px ; font-weight : bold">AI</button>
                <button type="button" @click="group_id = 2" class="btn col-6 text-white  btn-sm" :style="group_id == 2 ? {'border-radius' : '6px' , 'background-color' : 'black'} : ''" style="font-size : 13px ; font-weight : bold">Crypto</button>
            </div> -->
        
        
        <div v-if="group_id == 1" class="mt-3">
                

                <div class="mb-5">
                    <p v-if="spin_histories.length == 0" class="text-danger mt-5 text-center">{{$t('no_records')}}</p>
                    <div v-else v-for="(spin_history , i) in spin_histories" :key="i" class="card mt-3 bg-dark border-0 shadow-sm">
                        <div class="card-body text-dark" style="padding : 10px 10px 10px 10px">
                            <div class="row">
                                <div class="col-8 fw-bold text text-white">{{spin_history.product_name}}</div>
                                <div class="col-4 text fw-bold text-end text-white"> {{spin_history.spin_type}}</div>
                                <div class="text mt-2 text-white" style="font-size : 10px">{{spin_history.date}}</div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>


    </div>

    </div>
</template>

<script>

export default {
    data (){
        return {
           
           group_id : 1,
           ai_histories : [],
           spin_histories : [],
        }
    },

    watch: {
        group_id(new_value ){
            if(new_value == 1 && this.ai_histories.length == 0){
                this.getInvestmentHistories(new_value)
            }
            if(new_value == 2 && this.crypto_histories.length == 0){
                this.getInvestmentHistories(new_value)
            }
            
        }
    },

    methods : {
        getInvestmentHistories(){
            let point = this
            let enduser_id = this.$store.state.users.id
            
            this.axios.post(process.env.VUE_APP_BASE_URL+'/luckydraw/history',
            { enduser_id },
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
            ).then(function(response){
                console.log(response.data)
                
                // point.crypto_balance = response.data.crypto_balance
                // point.balance = response.data.balance
                point.spin_histories = response.data.data
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.removeItem('china_app_access_token')
                    location.reload()
                }
            })
        }

        
    },

    created(){
        let point = this
        point.getInvestmentHistories(1)
            
    }
}
</script>

<style>
    .header{
        background-color: #773DF5;
    }
    #detail_body{
        margin-top: 4rem;
    }
    #detail_body .input-group-text{
        font-size: 12px;
    }

    
</style>