<template>
  <div class="full-screen-container">
    <div class="row fixed-top">
      <div>
        <router-link to="/spinner">
          <fa
            class="text-white"
            style="position: absolute; top: 20px; left: 20px"
            :icon="['fas', 'arrow-left']"
          ></fa>
        </router-link>
        <hr class="mt-0 text-white">
      </div>
    </div>

    <div class="image-container">
      <img class="full-screen-image" src="../assets/spinner/rules_desc.jpg" alt="Rules Description">
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var elements = document.querySelectorAll(".mobile-bottom-nav");
    elements.forEach(function (element) {
      element.classList.add("d-none");
    });
  },
};
</script>

<style>
.full-screen-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-screen-image {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.row.fixed-top {
  z-index: 10; /* Ensure the top row is above the image */
}
</style>
