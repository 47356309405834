<template>
   <div class="bg-light" style="height : 100vh">
    
    <div class="row  fixed-top">
        <div class="bg-white">
             <!-- <router-link to="/">
                 <fa class="text-dark" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
             </router-link>
             
             <p class="text-dark text-center pt-3">{{$t('blog')}}</p> -->

            <div class="d-flex flex-column justify-content-center align-items-center">
                <Iconify class="text-white fw-bold" style="position : absolute ; top : 25px ; left : 30px; font-size: 19px !important; color: #3dac78 !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>

                <div class="fs-5 fw-semibold my-2" style="color: #3dac78 !important;">
                    <h6 class="fw-bold mt-3 text-center">{{$t('blog')}}</h6>
                </div>
            </div>
             <div id="divider"></div>
         </div>
       
    </div>

    <div class="p-3 bg-white" style="margin-top : 3.5rem">
        <div class="mt-3" v-for="(blog  , i) in blogNews" :key="i">
                <div class="ps-3 pe-3 row align-items-center" @click="newsDetail(blog)">
                    <div class="col-4">
                        <img :src="image_path+'storage/images/'+blog.cover_picture" class="img-fluid rounded">
                    </div>
                    <div class="col-8">
                        <h6 class="fw-bold">{{ blog.title }}</h6>
                        <p id="new_content" v-html="blog.short_content"></p>
                        <p class="text-muted float-end" style="font-size : 13px">{{blog.date == null ? '' : blog.date}}</p>

                    </div>
                </div>
                <div id="newsdivider"></div>
            </div>
    </div>

    <!-- <div class="p-3"> -->
        <!-- <div>
            <button class="btn col-12 text-white" @click="withdrawSubmit()" style="background-color: #773DF5;">提交</button>
        </div> -->
    <!-- </div> -->
    </div>
</template>

<script>

export default {
    data (){
        return {
            blogNews : '',
            image_path : ''
        }
    },

    methods : {
        newsDetail(blog){
            this.$store.commit('storeNewsDetail' , blog)
            this.$router.push('/news/detail')
        },
    },

    created(){
        var elements = document.querySelectorAll(".mobile-bottom-nav");
        elements.forEach(function(element) {
            element.classList.add("d-none");
        });
        
        this.image_path = process.env.VUE_APP_BASE_IMAGE_URL
        let point = this;
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/blog/news/get/all',
        {'enduser_id' : enduser_id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            console.log(response.data)
            point.blogNews = response.data.data
        }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })
                
    }
}
</script>

<style>
    .header{
        background-color: #773DF5;
    }
    #detail_body{
        margin-top: 4rem;
    }
    #detail_body .input-group-text{
        font-size: 12px;
    }
</style>