<template>
  <div class="p-4">
      <div class="d-flex justify-content-center align-items-center">
          <div class="fs-5 fw-semibold" style="color: #3dac78">{{$t('my_team')}}</div>
      </div>
      <div class="my-3 d-flex justify-content-end">
          <div class="btn btn-sm btn-success" style="background-color: #3dac78; border-color: #3dac78">
              <span><Iconify icon="iconamoon:profile-fill" style="font-size: calc(0.6rem + 1.5vw) !important;" class="me-1" /></span>{{member_count}}
          </div>
      </div>

      <div>
        <div class="card shadow-sm" style="border-color: #3dac78">
          <div class="card-body p-3 px-4">
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column justify-content-center align-items-center me-4">
                    <!-- <Iconify class="text-white" icon="et:wallet" style="font-size: calc(2.1rem + 1.5vw) !important;" /> -->
                    <img class="border" v-if="this.$store.state.users.profile != null" style="display: inline-block; width: 70px;height: 70px;border-radius: 50%;" width="" :src="image_path+'uploads/enduser_profile/'+this.$store.state.users.profile">
                    <img class="border" v-else style="display: inline-block; width: 70px;height: 70px;border-radius: 50%;" width="" src='../assets/mine/pp.jpg'>
                </div>
                <div>
                    <div class="d-flex flex-column justify-content-between align-items-start">
                        <div class="fs-6 fw-semibold mt-1" style="color: #3dac78; margin-bottom: 11px;">
                            {{ this.$store.state.users.member_name }} 
                            <span v-if="this.$store.state.users.level_id == 2" class="ms-2 btn btn-sm btn-success border-0 p-0 px-2 fw-semibold" style="background-color: #3dac78; border-color: #3dac78; font-size: 11px;">
                              Level {{this.$store.state.users.vip_level}}
                            </span>
                        </div>
                        <div>
                            <div class="" style="font-size: 14px; color: #b6b6b6;">
                                {{ this.$store.state.users.phone }}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="my-4 mb-5">
        <div class="fw-semibold">{{$t('team_members')}}</div>

        <div v-for="(member, index) in members" :key="index" class="my-3 card" style="border-color: rgb(50 188 123)">
          <div class="card-body p-3 px-4">
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column justify-content-center align-items-center me-5">
                    <!-- <img class="border" v-if="profile != null" style="display: inline-block; width: 60px;height: 60px;border-radius: 50%;" width="" :src="image_path+'uploads/enduser_profile/'+profile"> -->
                    <img class="border" style="display: inline-block; width: 50px;height: 50px;border-radius: 50%;" width="" src='../assets/mine/pp.jpg'>
                </div>
                <div>
                    <div class="d-flex flex-column justify-content-between align-items-start">
                        <div class="fs-6 mb-1 fw-semibold" style="color: #3dac78; font-size: 15px !important;">
                            {{ member.name }}
                        </div>
                        <div>
                            <div class="" style="font-size: 13px; color: #b6b6b6;">
                                {{ member.phone }}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      members: null,
      member_count: 0,
      image_path: null,
    };
  },

  methods: {
  },

  created() {

    let point = this;
    point.image_path = process.env.VUE_APP_BASE_IMAGE_URL;
    this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/user-tree',
        {'enduser_id' : this.$store.state.users.id , 'user_id' : this.$store.state.users.id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            console.log(response.data.data)
            point.members = response.data.data;
            point.member_count = response.data.data.length;
        }).catch((error)=>{
            console.log(error)

            if(error.response.status == 401){
                console.log(error.response)
                // localStorage.removeItem('china_app_access_token')
                // location.reload()
            }
        })
    console.log(this.$store.state.users)
  },
};
</script>

<style>
</style>
