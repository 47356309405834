<template>
    <div>
        <div class="px-4 mb-3" style="padding-top: 0.8rem !important;">
            <div>
                <Iconify class="" style="position : absolute ; top : 24px ; left : 30px; font-size: 17px !important; color: #3dac78;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <div class="text-center py-3 pt-2 fw-semibold" style="font-size: 15px; color: #3dac78;">
                    {{$t('investment_history')}}
                </div>
            </div>
            
        </div>

        <div class="mt-4 px-4">
            <div v-for="(history, index) in histories" :key="index" class="d-flex align-items-center mb-3" style="width: 100% !important;">
                <div class="card w-100" style="border-color: #3dac78 !important;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8 fs-6 fw-semibold">{{history.name}} <span style="color: #3dac78 !important;">x {{history.qty}}</span></div>
                            <div class="col-4 fw-bold text-end"> {{history.purchasing_price}} ¥</div>
                            <div class="mt-2" style="font-size : 10px">{{history.created_at}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data (){
        return {
           histories : [],
        }
    },

    methods : {
        getInvestmentHistories(){
            let point = this
            let enduser_id = this.$store.state.users.id
            
            this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/investment/history',
            { enduser_id },
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
            ).then(function(response){
                console.log(response.data)
                
                point.histories = response.data
                
                
            }).catch((error)=>{
                if(error.response.status == 401){
                    localStorage.removeItem('china_app_access_token')
                    location.reload()
                }
            })
        }

        
    },

    created(){
        console.log(this.$params)
        let point = this
        point.getInvestmentHistories(1)
            
    }
}
</script>