<template>
    <div class="bg-dark" style="height : 100vh">
     
     <div class="row bg-dark fixed-top" style="background: #000000">
         <div class="" style="margin-top : 20rem">
             <!-- <router-link to="/mine">
                 <fa class="text-white" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
             </router-link> -->
             <p class="text-center"><fa style="font-size : 5rem ; color : #E25215"  :icon="['fas', 'fa-screwdriver-wrench']"></fa></p>
             <p class=" text-center pt-3" style="font-size : 20px ; color : #E25215"> Under Maintenance ....</p>
             <!-- <div id="divider"></div> -->
         </div>
        
     </div>
 
     
 
     
     </div>
</template>
 
 <script>
 
 export default {
     data (){
         return {
            
         }
     },
 
     methods : {
         
     },
 
     created(){
         
     }
 }
 </script>
 
 <style>
     .header{
         background-color: #773DF5;
         
     }
     #detail_body{
         margin-top: 4rem;
     }
     #detail_body .input-group-text{
         font-size: 12px;
     }
 </style>