<template>
    <div>
        <!-- <div class="bg-image" id="wallet_head" style="height: 240px !important;"></div> -->
        <div class="px-4" style="padding-top: 0.5rem !important;">
            <div>
                <Iconify class="" style="position : absolute ; top : 20px ; left : 30px; font-size: 17px !important; color: #3dac78;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <div class="text-center py-3 pt-2 fw-semibold fs-6" style=" color: #3dac78;">
                    Contact Us
                </div>
            </div>

            <!-- <div class="glass_card shadow px-4">
                
                <div class="my-1 fs-1 fw-bold text-white">{{bank_card}}</div>
            
                <div class="fs-6 fw-semibold text-white">{{bank_name}}</div>
                
                <div class="fs-6 fw-semibold text-white">{{name}}</div>
                
            </div> -->
            
        </div>

        <div class="px-4 mt-3 mb-4">
            <img src="../assets/contactus/contactus.jpeg" class="img-fluid" alt="">
            
        </div>
    </div>
</template>

<script>

export default {

    created(){
        var elements = document.querySelectorAll(".mobile-bottom-nav");
        elements.forEach(function(element) {
            element.classList.add("d-none");
        });
    }
}
</script>
