<template>
    <div>

        <div class="bg-image" id="wallet_head" style="height: 240px !important;"></div>
        <div class="py-4 px-4" style="padding-top: 1rem !important;">
            <div>
                <!-- <router-link to="/mine"> -->
                    <Iconify class="text-white" style="position : absolute ; top : 26px ; left : 30px; font-size: 19px !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <!-- </router-link> -->
                <div class="text-center py-3 pt-2 text-white fw-semibold fs-6">
                {{$t('recharge')}}
            </div>
            </div>

            <div class="glass_card shadow px-4">
                <div>
                    <div class="text-white">{{$t('your_wallet_balance')}}</div>
                    <div class="my-1 fs-1 fw-bold">{{this.$store.state.users.balance}} ¥</div>
                </div>
                <div>
                    <div class="text-white">{{$t('name')}}</div>
                    <div class="fs-6 fw-semibold">{{this.$store.state.users.member_name}}</div>
                </div>
            </div>
            
        </div>
        <div class="card card-body shadow mx-4" style="border-color: #3dac78 !important; background-color: #eee !important">
            <div class="">
                <div class="fs-5 mb-2 fw-semibold">
                    <div class="">
                        <div style="color: #3dac78 !important;">
                            {{bank_info.company_account}}
                            <span class="ms-1">
                                <Iconify @click="copy(bank_info.company_account)" icon="ant-design:copy-outlined" style="font-size: calc(1rem + 1.5vw) !important; " />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="fs-5 my-1" style="font-size: 15px !important;">
                    <div>
                        {{bank_info.bank}}
                    </div>
                </div>
                <div class="fs-5" style="font-size: 15px !important;">
                    <div>
                        {{bank_info.account_holder}}
                    </div>
                </div>
                
            </div>
            
        </div>
        <div class="mt-4 px-4 " style="margin-bottom: 5rem !important;">
            <div class="mb-3">
                <label class="mb-2 fw-semibold">{{$t('currency')}}</label>
                <select class="form-control" v-model="wallet_type" style="border: none !important; background-color: #eee !important;" @change="handleCurrencyChange">
                    <option value="1">{{$t('china')}}</option>
                    <option value="2">{{$t('usdt')}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="mb-2 fw-semibold">{{$t('recharge_amount')}}</label>
                <input v-model="amount" type="number" class="form-control" aria-describedby="basic-addon3" style="border: none !important; background-color: #eee !important;">
            </div>

            <div class="mb-3">
                <label class="mb-2 fw-semibold" >{{$t('payment_screenshot')}}</label>
                <div class="border bg-white rounded p-2" >
                    <div class="mb-2 d-flex justify-content-center">
                        <input type="file" style="display: none" v-on:change="rechargeImage($event)" id="recharge_image_upload" name='file'>
                        <label title="Image" for="recharge_image_upload"> 
                            <Iconify class="mt-3" icon="ion:cloud-upload-outline" style="font-size: calc(2.1rem + 1.5vw) !important; color: #3dac78 !important;" />
                        </label>
                    </div>
                    <p class="text-danger text-center" style="font-size: 12px; color: #3dac78 !important;" >*{{$t('payment_screenshot')}}</p>
                </div>
                <div v-if="preview != null" class="border rounded p-2 mt-2">
                    <img  :src="preview" class="img-fluid mt-3" width="60">
                    <fa class="text-danger float-end" style="" :icon="['fas', 'times']" @click="removeImage()"></fa>
                </div>
            </div>

            <div class="">
                <button :disabled="loader ? true : false" class="btn col-12 text-white" @click="rechargeSubmit()" style="background-color: #3dac78;">{{$t('recharge')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
            <div class="mt-3">
                <button :disabled="popup_recharge_payment_loader ? true : false" class="btn col-12 text-white" @click="rechargePaymentSubmit()" style="background-color: #3dac78;">{{$t('recharge_payment')}}
                    <div class="lds-dual-ring" v-if="popup_recharge_payment_loader"></div>
                </button>
            </div>
        </div>
        <van-popup v-if="popup_showed == 1" v-model:show="show"   :style="{ width : '80%'  , 'border-radius' : '15px'}">
           <div class="card bg-white border-0">
                <!-- <div  class="border-0 d-flex justify-content-center bg-dark" style="height : 5rem ; background-color : #000000" > -->
                    <!-- <div class="bg-dark" style=" width: 70px ; height: 70px;border-radius: 50%;position : absolute ; top : 3rem"> -->
                        <!-- <img src="../assets/home/anic.png" width="55" class="rounded img-fluid" style="position : relative ; left : .6rem ; top : .5rem"> -->
                    <!-- </div> -->
               <!-- </div> -->

                <h4 class="text-center mt-2" style="" v-text="title"></h4>
                <div class="d-flex justify-content-center">
                    <div class="col-12 ps-3 pe-3">
                      <p class="cryptofs text-muted" style="font-family : Arial ; font-size : .9rem" v-html="content"></p>
                    </div>
                </div>
                <div class="d-flex justify-content-center border-0 bg-white card-footer" style="">
                    <button @click="show = false"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : #3dac78">Close</button>
                    </div>
                </div>

        </van-popup>
        <van-popup v-if="popup_recharge_payment == 1" v-model:show="show"   :style="{ width : '80%'  , 'border-radius' : '15px'}">
           <div class="card bg-white border-0">
                

                <h4  class="text-center mt-4" style="font-size:17px">{{payment_code}}</h4>
                
                <div class="d-flex gap-3 justify-content-center border-0 bg-white card-footer mt-3" style="">
                    <button @click="rechargePaymentSubmit('close')"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : red">Close</button>
                    <button @click="copyPaymentCode()"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : #3dac78">Copy</button>
                </div>
           </div>

        </van-popup>

    </div>
</template>

<script>
import { showSuccessToast , showFailToast } from 'vant';
import { ref } from 'vue';


export default {
    setup() {
        const show = ref(false);
        
        const showPopup = () => {
          show.value = true;
        };

        
        return {
           show,
           showPopup,

        };
    },
    data (){
        return {
            amount : null,
            images_types : ['image/png' , 'image/jpg' , 'image/jpeg'],
            preview : null,
            formData : new FormData(),
            loader : false,
            bank_info : [],
            wallet_type : 1,
            active_account : 1,
            amount_lists : [5000 , 10000 , 20000 , 50000 , 80000 , 100000],
            amount_active_index : null,
            popup_showed : 0,
            content: null,
            title: null,
            popup_recharge_payment : 0,
            popup_recharge_payment_loader : false,
            payment_code : '',
        }
    },
    watch: {
        active_account(new_value ){
            if(new_value == 2){
                this.wallet_type = 2
            }else{
                this.wallet_type = 1
            }
        }
    },
    methods : {
        handleCurrencyChange(e){
            let currency = e.target.value;
            if (currency == 2 && this.title != null) {
                this.popup_showed = 1;
                this.show = true;
            }
        },
        actm(i){
            this.amount_active_index = i
            this.amount = this.amount_lists[i]
        },
        copy(value){
           navigator.clipboard.writeText(value);
           showSuccessToast('Copied');
        //    this.$notify({ text: '复制成功',type: "",});
       },
        rechargeImage(e){
            const file = e.target.files[0]
            if (file) {
                let size   = file.size / (1024 * 1024);
                let actual_size = Number(size.toFixed(1))
                if(actual_size > 5){
                    showFailToast(this.$t('f_img_size'))
                    return;
                }
                if(this.images_types.includes(file.type)){
                    let link  = URL.createObjectURL(file)
                    this.preview = link
                    this.formData.append('image', file);

                }else{
                    showFailToast(this.$t('f_img_type'))
                }
            }
        },
        removeImage(){
            this.preview = null
        },

        rechargePaymentSubmit(close = null){
            let enduser_id = this.$store.state.users.id
            this.formData.append('enduser_id', enduser_id);
            let point = this
            this.axios.post(process.env.VUE_APP_BASE_URL+'/payment/code/generate',
                this.formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
            ).then(function(response){
                point.payment_code = response.data
                if(close == null){
                    point.popup_recharge_payment_loader = true;
                    point.popup_recharge_payment = 1;
                    point.show = true;
                }else{
                    point.popup_recharge_payment_loader = false;
                    point.show = false;
                    point.popup_recharge_payment = 0;
                }
            })
            
            
        },
        copyPaymentCode(){
           
            const Toaster = {
                postMessage: function(text) {
                // Send message to Flutter
                const message = {
                    channel: 'Toaster',
                    value: text
                };
                window.postMessage(JSON.stringify(message), '*');
                }
            };

            Toaster.postMessage(this.payment_code);

            navigator.clipboard.writeText(this.payment_code);
            showSuccessToast(this.$t('copied'))
            // this.$notify({ text: '复制成功',type: "success",});
        },

        rechargeSubmit(){
            if(this.amount != null){
                if(this.active_account == 2){
                    if(this.preview == null){
                        showFailToast(this.$t('missing_fields'))
                    }
                }
                this.loader = true
                let enduser_id = this.$store.state.users.id
                this.formData.append('currency_id', this.wallet_type);
                this.formData.append('recharge_amount', this.amount);
                this.formData.append('enduser_id', enduser_id);
                let point = this;
                this.axios.post(process.env.VUE_APP_BASE_URL+'/finance/online-recharge',
                this.formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                        }
                    }
                ).then(function(response){
                    // console.log(response.data)
                   if(response.data.status_code == 200){
                    if(point.active_account == 1){
                        // if(response.data.data.status == 200){
                        //     window.location = response.data.data.pay_url
                        // }else{
                            showSuccessToast(point.$t(response.data.message))
                            point.amount == null
                            setTimeout(()=>{
                                location.reload()
                            }, 1000)
                        // }
                    }else{
                        showSuccessToast(point.$t(response.data.message))
                        // point.$notify({ text: response.data.message});
                        point.amount == null
                        setTimeout(()=>{
                            location.reload()
                        }, 1000)
                    }
                    
                   }else{
                    showFailToast(point.$t(response.data.message))
                    // point.$notify({ text: response.data.message,type: "error",});
                    point.loader = false
                   }
                }).catch((error)=>{
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })
            }else{
                showFailToast(this.$t('missing_fields'))
                // this.$notify({ text: '缺少必填字段',type: "error",});
            }
        },

        
    },
    created(){
        let point = this;
        let enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/content',
        {'enduser_id' : enduser_id},
            {
                headers: {
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                }
            }
        ).then(function(response){
            // console.log(response.data);
            point.bank_info = response.data
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })

        this.axios.post(process.env.VUE_APP_BASE_URL+'/popup',
        {'enduser_id' : enduser_id, 'id' : 1},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            // console.log(response)
            point.content = response.data[0]?.content
            point.title   = response.data[0]?.title
            point.show = response.data[0]?.status == 1 ? true : false
        })
                
     }
}
</script>

<style>
    .header{
        background-color: #773DF5;
    }
    #detail_body{
        margin-top: 4rem;
    }
    #detail_body .input-group-text{
        font-size: 14px;
    }

    
</style>