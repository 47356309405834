<template>
    <div style="overflow-x: hidden !important;">
        <div class="bg-image" id="profile_head">
            
            <div class="p-4 pb-2" style="background: linear-gradient(90deg, rgba(61,172,120,.2) 0%, rgba(255,255,255,1) 100%);">
                <div class="text-white rounded-4">
                    <div class="">
                        <div class="d-flex justify-content-start">
                            <div class="d-flex flex-column me-4">
                                <!-- <Iconify class="text-white" icon="et:wallet" style="font-size: calc(2.1rem + 1.5vw) !important;" /> -->
                                <img class="border" v-if="profile != null" style="display: inline-block; width: 70px;height: 70px;border-radius: 50%;" width="" :src="image_path+'uploads/enduser_profile/'+profile">
                                <img class="border" v-else style="display: inline-block; width: 70px;height: 70px;border-radius: 15%;" width="" src='../assets/mine/pp.jpg'>
                            </div>
                            <div class="me-5">
                                <div class="d-flex flex-column justify-content-between align-items-start">
                                    <div class="fs-6 mt-2 fw-semibold text-dark" v-if="verified">
                                       {{ this.$store.state.users.member_name }}
                                    </div>
                                    <div class="fs-6 mb-2 mt-3" v-else>
                                       {{$t('not_varified')}}
                                    </div>
                                    <div class="mt-1">
                                        <div class="text-dark">
                                            {{ this.$store.state.users.phone }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end mt-2 fw-semibold badge rounded-pill text-success">
                                <span class="text-end badge rounded-pill text-success" style="background-color: rgba(61, 172, 120, 0.5);font-size: 12px">
                                    {{this.$store.state.users.level}} <span v-if="this.$store.state.users.level_id == 2">{{this.$store.state.users.vip_level}}</span>
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider">
               <div class="w-100" style="border: 1.2px solid #3DAC78"></div>
            </div>
        </div>

        <div class="p-2" >
            <div class="text-dark rounded" style="background: linear-gradient(90deg, rgba(240,238,238,1) 24%, rgba(61,172,120,.3) 100%);">
                <div class="p-4 card-body">
                    <div class="d-flex justify-content-between">
                        
                        <div>
                            <div class="d-flex  flex-column justify-content-between">
                                <div class="fs-6 mb-2">
                                    <span class="text-muted">{{$t('wallet_balance')}} (¥)</span>
                                    <span @click="balanceHide(false)"><Iconify  v-if="balance_show" class="ms-2" icon="iconoir:eye" style="font-size: calc(1.2rem + 1.5vw) !important;color: black ; font-weight:bold" /></span>
                                    <span @click="balanceHide(true)"><Iconify  v-if="!balance_show" class="ms-2" icon="fluent:eye-off-16-regular" style="font-size: calc(1.2rem + 1.5vw) !important;color: black ; font-weight:bold" /></span>
                                    <span class="badge rounded-pill text-success ps-2 pe-2 ms-2" style="background-color: rgba(61, 172, 120, 0.5);font-size: 10px"><Iconify style="position: relative ; bottom: 1px" icon="wpf:security-checked" /> 保障中</span>
                                </div>
                                <div>
                                    <div class="fs-3 fw-semibold">
                                        {{ balance_show ? this.$store.state.users.balance : '******'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <Iconify class="text-dark" icon="et:wallet" style="font-size: calc(1.5rem + 1.5vw) !important;" />
                        </div>
                    </div>
                </div>

                <div class="row p-3">
                    
                    <div class="col-6">
                        <router-link to="/withdraw/1">
                          <button class="btn col-12 text-white fw-bold p-2" style="background-color: #3DAC78 ; font-size:14px">{{$t('withdraw')}}</button>
                        </router-link>
                    </div>
                    <div class="col-6">
                        <router-link to="/recharge">
                             <button class="btn col-12 btn-outline-success text-success fw-bold p-2" style=" font-size:14px">{{$t('recharge')}}</button>
                        </router-link>
                    </div>
                    
                </div>
            </div>
        </div>


        <div class="p-2 pb-1">

            <div class="d-flex flex-column card border-0  rounded-3" style="background-color : #F1F1F1">
                
                <router-link to="/wallet" class="d-flex justify-content-between align-items-center  border-bottom" style="padding: 12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="et:wallet" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('wallet')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>
                <router-link to="/recharge" class="d-flex justify-content-between align-items-center  border-bottom" style="padding: 12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="solar:square-top-up-linear" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('recharge')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>

                <router-link to="/bank" class="d-flex justify-content-between align-items-center  border-bottom" style="padding: 12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="mingcute:bank-card-line" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('bank_card')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>
                
            </div>

        </div>

        <div class="p-2">
            <div class="d-flex flex-column card  border-0 rounded-3" style="background-color: #F1F1F1">
                <router-link to="/myteam" class="d-flex justify-content-between align-items-center  border-bottom" style="padding:12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="ant-design:team-outlined" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('my_team')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>
                <router-link to="/verified" class="d-flex justify-content-between align-items-center  border-bottom" style="padding:12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="material-symbols:verified-outline" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('verified')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>

                <router-link to="/usersetting" class="d-flex justify-content-between align-items-center  border-bottom" style="padding:12px">
                    <div class="text-dark fs-6">
                        <Iconify class="text-success me-2" icon="weui:setting-outlined" style="font-size: calc(.8rem + 1.5vw) !important;" />{{$t('setting')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.2rem + 1.5vw) !important;" />

                    </div>
                </router-link>
                
            </div>

        </div>


        <div class="p-4">
            <!-- <ul class="list-group rounded"> -->
                <!-- <router-link  to="/recharge" id="router">
                    <li class="list-group-item bg-white rounded-top" aria-disabled="true">
                   
                        <img src="../assets/mine/1.png" class="img-fluid" width="20">
                        <span class="" id="fm2">{{$t('recharge')}}</span>
                        <span class="float-end"><fa :icon="['fas', 'arrow-right']"></fa></span>
                    </li>
                </router-link> -->

                <!-- <router-link to="/investment" id="router">
            
                    <li class="list-group-item" aria-disabled="true" >
                        <img src="../assets/mine/2.png" class="img-fluid" width="20">
                        <span class="" id="fm2">我要缴费</span>
                        <span class="float-end text-white"><fa :icon="['fas', 'arrow-right']"></fa></span>
                    </li>
                </router-link> -->

        <!-- <router-link to="/transaction/record" id="router">

            <li class="list-group-item" aria-disabled="true">
                <img src="../assets/mine/3.png" class="img-fluid" width="20">
                <span class="" id="fm2">Transaction Record</span>
                <span class="float-end text-white"><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link> -->
        <!-- <router-link to="/myteam" id="router">

            <li class="list-group-item bg-white" aria-disabled="true">
                <img src="../assets/mine/4.png" class="img-fluid" width="20">
                <span class="" id="fm2">{{$t('my_team')}}</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link>
        <router-link to="/wallet" id="router">

            <li class="list-group-item bg-white" aria-disabled="true">
                <img src="../assets/mine/1.png" class="img-fluid" width="20">
                <span class="" id="fm2">{{$t('wallet')}}</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link> -->
        <!-- <router-link to="/withdraw" id="router">

            <li class="list-group-item" aria-disabled="true">
                <img src="../assets/mine/1.png" class="img-fluid" width="20">
                <span class="" id="fm2">Withdraw</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link> -->
        <!-- <router-link to="/verified" id="router">

            <li class="list-group-item bg-white" aria-disabled="true">
                <img src="../assets/mine/5.png" class="img-fluid" width="20">
                <span class="" id="fm2">{{$t('verified')}}</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link>
        <router-link to="/bank" id="router">

            <li class="list-group-item bg-white" aria-disabled="true">
                <img src="../assets/mine/6.png" class="img-fluid" width="20">
                <span class="" id="fm2">{{$t('bank_card')}}</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link>
        <router-link to="/usersetting" id="router">

            <li class="list-group-item bg-white rounded-bottom" aria-disabled="true">
                <fa class="" style="position : relative ; top : 3px" id="fai" :icon="['fas', 'gear']" />
                <span class="" id="fm2">{{$t('setting')}}</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link>
        <a @click="goToChat()" v-if="enduser_id == 1519" to="/usersetting" id="router">

            <li class="list-group-item bg-white rounded-bottom" aria-disabled="true">
                <fa class="" style="position : relative ; top : 3px" id="fai" :icon="['fas', 'gear']" />
                <span class="" id="fm2">Chat</span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </a> -->

        
        <!-- <router-link to="/bank" id="router">

            <li class="list-group-item rounded-bottom" aria-disabled="true">
                <img src="../assets/mine/6.png" class="img-fluid" width="20">
                <span class="" id="fm2"></span>
                <span class="float-end "><fa :icon="['fas', 'arrow-right']"></fa></span>
            </li>
        </router-link> -->

            
            <!-- </ul> -->
            <button class="btn btn-outline-success w-100  mb-5" @click="signout()">{{$t('logout')}}</button>
        </div>
    </div>
</template>

<script>
 import {showSuccessToast} from 'vant';

export default {
    data(){
        return {
            balance : 0,
            crypto_balance : 0,
            profile : null,
            level : null,
            vip_level : '',
            verified : false,
            image_path : process.env.VUE_APP_BASE_IMAGE_URL,
            enduser_id : null,
            balance_show : true,

        }
    },
    methods : {
        balanceHide(status){
            this.balance_show = status
        },

        goToChat(){
            window.location.href = 'ultronchat://open.ultron.app';
        },
        signout(){
            let point = this;
            this.$swal.fire({
            title: '<span style="font-size : 16px">'+ point.$t('logout_confirmation_text') +' ?</span>',
            showCancelButton: 'true',
            confirmButtonText: point.$t('confirm'),
            cancelButtonText: point.$t('cancel'),
            }).then((result) => {
            if (result.isConfirmed) {
                // let point = this;
                let enduser_id = this.$store.state.users.id
                    this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/signout',
                        {'enduser_id' : enduser_id},
                            {
                                headers: {
                                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                }
                            }
                        ).then(function(response){
                            // console.log(response.data)
                        if(response.data.status_code == 200){
                            localStorage.removeItem('china_app_access_token')
                            showSuccessToast('成功退出')

                            // point.$notify({ text: '成功退出',type: "",});
                            setTimeout(()=>{
                                location.reload()
                            }, 2000)
                        }
                        }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.removeItem('china_app_access_token')
                            location.reload()
                        }
                    })
                
            }
            })

        },
    },

    created(){
        let point = this;
        console.log(this.$store.state.users)
        let enduser_id = this.$store.state.users.id
        this.enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/investment/record',
        {'enduser_id' : enduser_id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            // console.log(response.data.data.ai_investment[0].investment)
            if(response.data.data.ai_investment[0].investment == null){
                // console.log(response.data.data.ai_investment[0].investment)
                point.balance = 0
            }else{
                 let ai_investment = response.data.data.ai_investment[0].investment;
                 point.balance = Number(ai_investment).toFixed(2)
            }

            if(response.data.data.crypto_investment[0].investment == null){
                point.crypto_balance = 0
            }else{
                let crypto_investment = response.data.data.crypto_investment[0].investment;
                point.crypto_balance = Number(crypto_investment).toFixed(2)
            }
        }).catch((error)=>{
            // console.log(error)
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })


        this.axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
        {'enduser_id' : enduser_id},
        
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            
            if(response.data.status_code == 200){
                point.verified = true
            }
        })

        this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/balance',
            {'enduser_id' : enduser_id},
            
            {
                headers: {
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                }
            }
            ).then(function(response){
                // console.log(response)
                point.level = response.data.level_id
                point.vip_level = response.data.vip_level
                point.profile = response.data.profile
            })
    }
}
</script>

<style>
#profile_head {
    /* width: 100%;
    background: url('../assets/home/profile_bg.png') no-repeat;
    background-size: cover;
    position: relative; */
}
#mineqrcard{
    /* margin-top : 17rem */
    /* position: relative;
	top:17rem; 
    margin-top : 3rem;*/
    margin-bottom : 3rem;
	width: 100%;
}
#totalPayment{
    
    position: absolute;
	top:10rem;
	width: 100%;
    
}
#userSetting{
    position: absolute;
	top:1rem;
	width: 100%;
    
}

.mineBg {
  /* background-image: url("../assets/mine/bg3.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  background-position: center;
}

#fm2 {
    font-size: 12px;
    
    margin-left: 1rem;
    font-weight :500
}
#mineqrcard .list-group-item{
    padding : 12px 10px 12px 10px;
}
.usn{
    font-family : Arial;
    font-size: 18px;
    color: white;
    font-weight: bold;
}
.crown_text{
    /* position : absolute; */
    font-size: 11px;
    /* right : 3rem; */
    /* top : 1.7rem; */
    color : white
}

</style>