<template>
  <div id="spin_bg">
    <div class="row fixed-top">
      <div class="" style="background-color: rgba(0, 0, 0, 0); z-index: -10">
        <router-link to="/">
          <fa
            class="text-white"
            style="position: absolute; top: 20px; left: 20px"
            :icon="['fas', 'arrow-left']"
          ></fa>
        </router-link>
        <router-link to="/spinner/history">
          <van-icon
            name="underway-o"
            class="text-white"
            style="position: absolute; top: 20px; right: 20px"
          />
        </router-link>
        <p class="text-white text-center pt-3">{{ $t("spin_wheel") }}</p>
      </div>
    </div>

    <div class="">
      <div
        style="background-color : rgba(0,0,0,.5) ; position: absolute ; top : 3rem ; padding : 0px ; z-index-10"
        class="w-100 text-white"
      >
        <div v-if="winners.length > 0">
          <marquee>
            <span v-for="(winner, index) in winners" :key="index">{{ ' ' +winner.member_name + '(' + '+91'+Number(winner.phone).toString().slice(0, 3)+'*****'+')' + ' ,'}}</span>
          </marquee>
        </div>
      </div>
      <div
        style="position: absolute ; top : 4.2rem ; padding : 5px ; z-index-10"
        class="w-100 text-white"
      >
        <h1
          class="text-center"
          style="
            color: #e25215;
            font-size: 2.5rem;
            letter-spacing: 1px;
            position: relative;
            right: 0.5rem;
          "
        >
          Ordinary
        </h1>
        <h1
          class="text-center"
          style="
            color: #ffffff;
            font-size: 1.5rem;
            position: relative;
            bottom: 0.5rem;
            left: 3rem;
          "
        >
          Turntable
        </h1>
      </div>
      <div class="" style="">
        <router-link to="/spinner/rules">
          <img
            width="80"
            src="../assets/spinner/rules1.png"
            style="position: absolute; top: 13rem; z-index: 100"
          />
        </router-link>
        <router-link to="/spinner/prizes/announcement">
          <img
            width="80"
            src="../assets/spinner/prizes.png"
            style="position: absolute; top: 13rem; right: 0; z-index: 100"
          />
        </router-link>

        <div ref="" class="d-flex justify-content-center">
          <img
            width="520"
            src="../assets/spinner/frame4.png"
            style="position: absolute; top: 9.2rem; z-index: 10"
          />

          <FortuneWheel
            @click="buySpinAlert()"
            v-if="prizesCanvas.length > 1"
            style="position: absolute; top: 16rem"
            :prizeId="prizeId"
            :verify="canvasVerify"
            :canvas="canvasOptions"
            :prizes="prizesCanvas"
            :disabled="spin_disable"
            @rotateEnd="onRotateEnd"
            @rotateStart="onCanvasRotateStart"
            @onChangePrize="onChangePrize"
          />

          <div
            class="d-flex justify-content-center"
            style="position: absolute; top: 37rem"
          >
            <button
              class="btn btn-sm text-white me-3"
              style="background-color: #e25215; width: 7rem"
            >
              <img src="../assets/spinner/lefts.png" width="15" class="me-1" />{{
                spin_chance
              }}
              {{ $t("spin_left") }}
            </button>
            <button
              @click="show = true"
              class="btn btn-sm text-white ms-3"
              style="background-color: #e25215; width: 7rem; z-index: 10"
            >
              <img src="../assets/spinner/buys.png" width="15" class="me-1" />
              {{ $t("buy_spin") }}
            </button>
          </div>
        </div>

        <div class="w-100 ps-3 pe-3" style="position: absolute; bottom: 0.5rem">
          <van-steps
            class="pt-3 pb-4 ps-4 pe-4 rounded"
            style="background-color: black"
            :active="active"
            active-icon="success"
            active-color="#07c160"
          >
            <van-step v-for="(step, i) in prizeSteps" :key="i">{{ step }}</van-step>
          </van-steps>
        </div>
        <div class="d-flex justify-content-center">
          <p
            class="text-white"
            style="position: absolute; bottom: 0.5rem; font-size: 12px"
          >
            Total spinned : {{ total_spinned }}
          </p>
        </div>
      </div>
    </div>

    <van-popup
      class="bg-dark text-white"
      v-model:show="show"
      position="bottom"
      :style="{ height: '50%', 'background-color': 'white' }"
      @click-overlay="onClickOverlay"
      @click-close-icon="onClickCloseIcon"
    >
      <div class="p-3">
        <span class="text-danger">* Your balance - {{ balance }} ¥ </span><br />
        <span class="text-primary">1 spin chance = {{ spin_sell_price }} ¥ </span>
        <div class="mt-3">
          <label>Fund Password</label>
          <div class="input-group pt-2">
            <input
              type="password"
              v-model="password"
              class="form-control"
              placeholder="Investment password"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>

        <div class="mt-3">
          <label>Quantity</label>

          <div class="input-group pt-2">
            <span
              class="input-group-text text-white"
              style="background-color: #e25215; border: 1px solid #e25215"
              @click="qty > 1 ? qty-- : ''"
              >-</span
            >
            <input
              type="text"
              v-model="qty"
              class="form-control text-center"
              aria-label="Amount (to the nearest dollar)"
            />
            <span
              class="input-group-text text-white"
              style="background-color: #e25215; border: 1px solid #e25215"
              @click="qty++"
              >+</span
            >
          </div>
        </div>
        <button
          :disabled="loader ? true : false"
          class="btn col-12 text-white mt-4"
          @click="buySpin()"
          style="background-color: #e25215"
        >
          Submit
          <div class="lds-dual-ring" v-if="loader"></div>
        </button>
        <!-- <button class="btn btn-primary w-100 mt-4" @click="choose()">Confirm</button> -->
      </div>
    </van-popup>

    <!-- <div class="d-flex justify-content-center" v-if="restrict_show" >
        <img v-if="winded_prizes != null" width="291" src="../assets/spinner/winframe.png"  class="rounded" style="width : 291px ; position : absolute ; top : 7rem ;z-index : 3000">
        <img v-if="winded_prizes == null" width="300" src="../assets/spinner/win_spread.png"  class="rounded"  style="position: absolute; top: 25vh; z-index: 4000"
>
    </div> -->

    <van-popup
      @close="reloadPage()"
      v-model:show="restrict_show"
      style="z-index: 3000"
      :style="{ width: '291px', 'border-radius': '15px' }"
    >
      <div class="card bg-white text-white border-0">
        <div
          class="border-0 d-flex justify-content-center bg-white"
          style="height: 4rem; background-color: #000000"
        >
          <img
            v-if="winded_prizes == null"
            src="../assets/spinner/lose.png"
            width="63"
            class="rounded img-fluid"
            style="position: relative; left: 0.6rem; top: 0.5rem"
          />
          <img
            v-else
            src="../assets/spinner/win.png"
            width="63"
            class="rounded img-fluid"
            style="position: relative; left: 0.6rem; top: 0.5rem"
          />
        </div>
        <!-- <div class="bg-dark" style=" width: 70px ; height: 70px;border-radius: 50%;position : absolute ; top : 3rem">
                 </div> -->
        <h4
          class="text-center mt-3"
          :class="winded_prizes == null ? 'text-danger' : 'text-primary'"
          v-text="winded_prizes == null ? 'Ohh , Sorry' : 'Congratulations'"
        ></h4>
        <div class="border-0 ps-3 pe-3">
          <!-- <p class="mt-3 text-muted text-center" style="font-family : Arial ; font-size : 15px" v-text="''"></p> -->
          <div class="d-flex justify-content-center" v-if="winded_prizes != null">
            <img
              :src="image_path + 'storage/' + winded_prizes.img"
              width="100"
              class="img-fluid"
            />
          </div>

          <p
            class="mt-2 text-muted text-center"
            style="font-family: Arial; font-size: 15px"
          >
            {{
              winded_prizes == null
                ? "You are not lucky for this time , wish you all the best in next time"
                : "You win " + '"' + winded_prizes.name + '"'
            }}
          </p>
        </div>
        <div class="d-flex justify-content-center border-0 bg-white card-footer" style="">
          <button
            v-if="winded_prizes != null && winded_prizes.prize_type == 3"
            @click="$router.push('/win-spinner')"
            class="btn text-white btn-sm mb-2"
            style="border-radius: 15px; background-color: #e25215"
          >
            Go to Win Spinner
          </button>
          <button
            v-else
            @click="reloadPage()"
            class="btn text-white btn-sm mb-2"
            style="border-radius: 15px; width: 5rem; background-color: #e25215"
          >
            Close
          </button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
import FortuneWheel from "vue-fortune-wheel";
import { showSuccessToast, showFailToast } from "vant";

export default {
  setup() {
    const active = ref("null");
    const show = ref(false);
    const restrict_show = ref(false);

    const onClickOverlay = () => {
      // showSuccessToast('closed');
    };

    const onClickCloseIcon = () => {
      // showToast('closed');
    };

    const onClickTab = () => {
      //   console.log(product);
      // point.changeProduct(pi)
    };

    return {
      active,
      show,
      restrict_show,
      onClickOverlay,
      onClickCloseIcon,
      onClickTab,
    };
  },

  components: {
    FortuneWheel,
  },
  data() {
    return {
      canvasVerify: ref(false),
      prizeId: 0,
      fontSize: 60,
      verifyDuration: 2,
      balance: 0,
      spin_sell_price: 0,
      spin_chance: 0,
      loader: false,
      password: null,
      qty: 1,
      winded_prizes: null,
      spin_disable: true,
      total_spinned: 0,

      canvasOptions: {
        btnWidth: 40, // Adjusted button width
        btnText: "START", // Adjusted button width
        borderColor: "#E25215",
        borderWidth: 0, // Adjusted border width
        lineHeight: 30,
        width: 500, // Adjusted width
        height: 500, // Adjusted height
        fontSize: 16, // Font size for text on segments
        textFontColor: "#ffffff", // Font color for text on segments
        textLength: 10, // Margin for text on segments
        textRadius: 215,
      },
      prizesCanvas: [
        {
          id: -1,
          name: "Thank you",
          value: "Thanks",
          bgColor: "#EBE7E6",
          color: "#E25215",
          probability: 0,
        },
      ],
      prizeSteps: [50, 200, 400, 600, 1000],
      image_path: process.env.VUE_APP_BASE_IMAGE_URL,
      winners: [],
    };
  },
  methods: {
    testRequest(verified, duration) {
      // verified: whether to pass the verification, duration: delay time
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(verified);
        }, duration);
      });
    },

    buySpinAlert() {
      if (this.spin_chance <= 0) {
        showFailToast("Please buy spin first!");
      }
    },

    onCanvasRotateStart(rotate) {
      if (this.canvasVerify.value) {
        const verified = true;
        this.testRequest(verified, this.verifyDuration * 1000).then((verifiedRes) => {
          if (verifiedRes) {
            console.log("Verification passed, start to rotate");
            rotate();
            this.canvasVerify.value = false;
          } else {
            alert("Failed verification");
          }
        });
        return;
      }
      this.canvasOptions.btnDisabled = false;

      this.getResult();
      console.log("onCanvasRotateStart");
    },
    onRotateEnd(prize) {
      let point = this;
      this.restrict_show = true;
      if (prize.prize_type == 3) {
        setTimeout(() => {
          point.$router.push("/win-spinner");
        }, 3000);
      }
    },

    onChangePrize() {
      // console.log('aa')
      this.prizeId = 2;
    },

    setWheelSize() {
      const wheelContainer = this.$refs.wheelContainer;
      if (wheelContainer) {
        wheelContainer.style.width = "300px";
        wheelContainer.style.height = "300px";
      }
    },

    reloadPage() {
      this.restrict_show = false;
      if (this.winded_prizes != null && this.winded_prizes.prize_type == 3) {
        this.$router.push("/win-spinner");
      } else {
        // this.$router.push('/spinner')
        location.reload();
      }
    },
    buySpin() {
      let point = this;
      let enduser_id = this.$store.state.users.id;

      if (point.qty != null && point.password != null) {
        point.loader = true;
        this.axios
          .post(
            process.env.VUE_APP_BASE_URL + "/luckydraw/topupSpinChance",
            {
              enduser_id: enduser_id,
              qty: point.qty,
              password: point.password,
            },
            {
              headers: {
                Authorization:
                  "Bearer " +
                  JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
              },
            }
          )
          .then(function (response) {
            console.log(response.data);
            if (response.data.status_code == 200) {
              showSuccessToast(point.$t(response.data.message));
              setTimeout(() => {
                location.reload();
              }, 1000);
            } else {
              point.loader = false;
              showFailToast(point.$t(response.data.message));
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              localStorage.removeItem("china_app_access_token");
              location.reload();
            }
          });
      } else {
        showFailToast(point.$t("missing_fields"));
      }
    },

    getResult() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/luckydraw",
          { enduser_id: enduser_id, spin_type: 1 },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          let result = response.data.data;
          point.winded_prizes = result;
          // console.log(result);

          if (result == null) {
            let thanks = [point.prizesCanvas[0].id, 15, 18];
            const randomIndex = Math.floor(Math.random() * thanks.length);
            // console.log(thanks[randomIndex])
            point.prizeId = thanks[randomIndex];
          } else {
            point.prizeId = result.id;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },
    getWinners() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL + "/luckydraw/winnerList?enduser_id=" + enduser_id,
          {
            headers: {
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          let result = response.data.data;
          point.winners = result;
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },

    getSpinPrizes() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/luckydraw/product",
          { enduser_id: enduser_id, spin_type: 1 },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          point.spin_chance = response.data.data.chances;
          point.total_spinned = response.data.data.total_spinned;
          let active_step = point.prizeSteps.findLastIndex(
            (e) => response.data.data.total_spinned >= e
          );
          point.active = active_step;

          if (point.spin_chance > 0) {
            point.spin_disable = false;
          }
          point.balance = response.data.data.user_balance;
          point.spin_sell_price = response.data.data.spin_price;
          let prizes = response.data.data.products;

          point.prizesCanvas[0]["id"] = prizes[prizes.length - 1]["id"] + 1;

          let remainder = 0;
          let probability = Math.floor(100 / prizes.length);
          prizes.map((prize, i) => {
            remainder = 100 % prizes.length;
            let add_remainder = i == 0 ? remainder : 0;
            let add_probability = add_remainder + probability;

            point.prizesCanvas.push({
              id: prize.id,
              name: prize.name,
              probability: Number(add_probability),
              bgColor: i % 2 != 0 ? "#EBE7E6" : "#ffffff",
              color: "#E25215",
              value: prize.name,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },
  },
  mounted() {
    this.setWheelSize();
  },
  created() {
    var elements = document.querySelectorAll(".mobile-bottom-nav");
    elements.forEach(function (element) {
      element.classList.add("d-none");
    });
    this.getSpinPrizes();
    this.getWinners();
  },
};
</script>

<style>
#topParent {
  margin-top: 0px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  /* border : 2px solid green; */
  /* border-radius : 50% */
}
canvas {
  width: 300px !important;
  height: 300px !important;
}
.indicator {
  position: absolute;
  top: 20px; /* Adjusted position */
  left: 50%;
  transform: translateX(-80%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid red; /* Color of the indicator */
  z-index: 10; /* Ensure it is on top of the wheel */
}
.fw-btn {
  margin-top: 10rem;
}
#spin_bg {
  background-image: url("../assets/spinner/bg3.png");
  background-color: #e25215;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-position-y: -20%; */
}
.fw-btn__btn {
  /* background-color: #cccccc; */
  /* width : 40px !important; */
  /* height : 40px !important; */
  position: relative;
  left: 16%;
  top: 1.5rem;
  font-weight: bold;
}
.fw-btn {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e25215;
  color: white;
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  z-index: 20;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  /* box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px; */
}

.van-step__circle-container {
  background-color: black !important;
}
</style>
