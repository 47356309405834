<template>
    <div class="bg-light" style="height : 100vh">
     
    <div class="fixed-top bg-white">
        <!-- <router-link to="/news/all">
            <fa class="text-dark p-4"  :icon="['fas', 'arrow-left']"></fa>
        </router-link> -->
        <div class="d-flex flex-column justify-content-center align-items-center">
            <Iconify class="text-white fw-bold" style="position : absolute ; top : 25px ; left : 30px; font-size: 19px !important; color: #3dac78 !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>

            <div class="fs-5 fw-semibold my-2" style="color: #3dac78 !important;">
                <h6 class="fw-bold mt-3 text-center">{{ title }}</h6>
            </div>
        </div>
        <div id="divider"></div>

        
     </div>
     <div class="p-3 bg-white" style="margin-top : 3.5rem">
         <div class="ps-3 pe-3 pb-5">
                 <img :src="image_path+'storage/images/'+image" class="img-fluid rounded">
                
                 <p class="text-dark  mt-3" style="font-size : 13px">{{date == null ? '' : date}}</p>

                 <div class="mt-3">
                     <p id="new_content" v-html="content"></p>
                 </div>
         </div>
     </div>
 
     <!-- <div class="p-3">
         <div>
             <button class="btn col-12 text-white" @click="withdrawSubmit()" style="background-color: #773DF5;">提交</button>
         </div>
     </div> -->
     </div>
</template>
 
 <script>
 
 export default {
     data (){
         return {
            image_path : process.env.VUE_APP_BASE_IMAGE_URL,
            image : this.$store.state.newsDetail.cover_picture,
            title : this.$store.state.newsDetail.title,
            content : this.$store.state.newsDetail.content,
            date : this.$store.state.newsDetail.date,
         }
     },
 
    
 
     created(){  
         if(!this.image){
            this.$router.push('/')
         }
     }
 }
 </script>
 
 <style>
     .header{
         background-color: #773DF5;
     }
     #detail_body{
         margin-top: 4rem;
     }
     #detail_body .input-group-text{
         font-size: 12px;
     }
 </style>