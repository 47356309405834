<template>
  <div id="spin_bg_w">
    <div class="row fixed-top">
      <div class="" style="background-color: rgba(0, 0, 0, 0); z-index: -10">
        <router-link to="/mine">
          <fa
            class="text-white"
            style="position: absolute; top: 20px; left: 20px"
            :icon="['fas', 'arrow-left']"
          ></fa>
        </router-link>
        <p class="text-white text-center pt-3">Upgrade Turntable</p>
      </div>
    </div>

    <div class="">
      <div
        style="background-color : rgba(0,0,0,.5) ; position: absolute ; top : 3rem ; padding : 0px ; z-index-10"
        class="w-100 text-white"
      >
        <!-- <div v-html="'<marquee>Winner Aung Myat , Aung Khant</marquee>'"></div> -->
      </div>
      <div
        style="position: absolute ; top : 4.2rem ; padding : 5px ; z-index-10"
        class="w-100 text-white"
      >
        <h1
          class="text-center"
          style="
            color: #e25215;
            font-size: 2.5rem;
            letter-spacing: 1px;
            position: relative;
            right: 0.5rem;
          "
        >
          Ordinary
        </h1>
        <h1
          class="text-center"
          style="
            color: #ffffff;
            font-size: 1.5rem;
            position: relative;
            bottom: 0.5rem;
            left: 3rem;
          "
        >
          Turntable
        </h1>
      </div>
      <div class="" style="">
        <img
          width="80"
          src="../assets/spinner/rules.png"
          style="position: absolute; top: 13rem; z-index: 10"
        />
        <img
          width="80"
          src="../assets/spinner/prizes.png"
          style="position: absolute; top: 13rem; right: 0; z-index: 10"
        />
        <div ref="" class="d-flex justify-content-center">
          <img
            width="520"
            src="../assets/spinner/wsframe.png"
            style="position: absolute; top: 9.1rem; z-index: 10"
          />

          <FortuneWheel
            v-if="prizesCanvas.length > 0"
            style="position: absolute; top: 16rem"
            :prizeId="prizeId"
            :verify="canvasVerify"
            :canvas="canvasOptions"
            :prizes="prizesCanvas"
            @rotateEnd="onRotateEnd"
            @rotateStart="onCanvasRotateStart"
            @onChangePrize="onChangePrize"
          />
        </div>
      </div>
    </div>

    <!-- <div class="d-flex justify-content-center" v-if="restrict_show" >
        <img v-if="winded_prizes != null" src="../assets/spinner/winframe.png"  class="rounded" style="position : absolute ; top : 7rem ;z-index : 3000;width : 291px">
        <img v-if="winded_prizes == null" src="../assets/spinner/sorryframe.png"  class="rounded" style="position : absolute ; top : 7rem ;z-index : 3000;width : 291px">
    </div> -->

    <van-popup
      @close="reloadPage()"
      v-model:show="restrict_show"
      :style="{ width: '80%', 'border-radius': '15px' }"
    >
      <div class="card bg-white text-white border-0">
        <div
          class="border-0 d-flex justify-content-center bg-white"
          style="height: 4rem; background-color: #000000"
        >
          <img
            v-if="winded_prizes == null"
            src="../assets/spinner/lose.png"
            width="63"
            class="rounded img-fluid"
            style="position: relative; left: 0.6rem; top: 0.5rem"
          />
          <img
            v-else
            src="../assets/spinner/win.png"
            width="63"
            class="rounded img-fluid"
            style="position: relative; left: 0.6rem; top: 0.5rem"
          />
        </div>
        <h4
          class="text-center mt-3"
          :class="winded_prizes == null ? 'text-danger' : 'text-primary'"
          v-text="winded_prizes == null ? 'Ohh , Sorry' : 'Congratulations'"
        ></h4>
        <div class="border-0 ps-3 pe-3">
          <!-- <p class="mt-3 text-muted text-center" style="font-family : Arial ; font-size : 15px" v-text="''"></p> -->
          <div class="d-flex justify-content-center" v-if="winded_prizes != null">
            <img
              :src="image_path + 'storage/' + winded_prizes.img"
              width="100"
              class="img-fluid"
            />
          </div>
          <p
            class="mt-2 text-muted text-center"
            style="font-family: Arial; font-size: 15px"
          >
            {{
              winded_prizes == null
                ? "You are not lucky for this time , wish you all the best in next time"
                : "You win " + '"' + winded_prizes.name + '"'
            }}
          </p>
        </div>
        <div class="d-flex justify-content-center border-0 bg-white card-footer" style="">
          <button
            @click="reloadPage()"
            class="btn text-white btn-sm mb-2"
            style="border-radius: 15px; width: 5rem; background-color: #e25215"
          >
            Close
          </button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
import FortuneWheel from "vue-fortune-wheel";
import { showSuccessToast, showFailToast } from "vant";

export default {
  setup() {
    const show = ref(false);
    const restrict_show = ref(false);

    const onClickOverlay = () => {
      showSuccessToast("closed");
    };

    const onClickCloseIcon = () => {
      // showToast('closed');
    };

    const onClickTab = () => {
      // console.log(product);
      // point.changeProduct(pi)
    };

    return {
      show,
      restrict_show,
      onClickOverlay,
      onClickCloseIcon,
      onClickTab,
    };
  },

  components: {
    FortuneWheel,
  },
  data() {
    return {
      canvasVerify: ref(false),
      prizeId: 0,
      fontSize: 60,
      verifyDuration: 2,
      balance: 0,
      spin_sell_price: 0,
      spin_chance: 0,
      loader: false,
      password: null,
      qty: 1,
      winded_prizes: null,

      canvasOptions: {
        btnWidth: 40, // Adjusted button width
        btnText: "START", // Adjusted button width
        borderColor: "#E25215",
        borderWidth: 0, // Adjusted border width
        lineHeight: 30,
        width: 500, // Adjusted width
        height: 500, // Adjusted height
        fontSize: 16, // Font size for text on segments
        textFontColor: "#ffffff", // Font color for text on segments
        textLength: 10, // Margin for text on segments
        textRadius: 215,
      },
      prizesCanvas: [],
    };
  },
  methods: {
    testRequest(verified, duration) {
      // verified: whether to pass the verification, duration: delay time
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(verified);
        }, duration);
      });
    },

    onCanvasRotateStart(rotate) {
      if (this.canvasVerify.value) {
        const verified = true;
        this.testRequest(verified, this.verifyDuration * 1000).then((verifiedRes) => {
          if (verifiedRes) {
            console.log("Verification passed, start to rotate");
            rotate();
            this.canvasVerify.value = false;
          } else {
            alert("Failed verification");
          }
        });
        return;
      }
      this.getResult();
      console.log("onCanvasRotateStart");
    },
    onRotateEnd() {
      this.restrict_show = true;
    },

    onChangePrize() {
      // console.log('aa')
      // this.prizeId = 2;
    },

    setWheelSize() {
      const wheelContainer = this.$refs.wheelContainer;
      if (wheelContainer) {
        wheelContainer.style.width = "300px";
        wheelContainer.style.height = "300px";
      }
    },

    reloadPage() {
      this.restrict_show = false;
      this.$router.push("/spinner");
    },
    buySpin() {
      let point = this;
      let enduser_id = this.$store.state.users.id;

      if (point.qty != null && point.password != null) {
        point.loader = true;
        this.axios
          .post(
            process.env.VUE_APP_BASE_URL + "/luckydraw/topupSpinChance",
            {
              enduser_id: enduser_id,
              qty: point.qty,
              password: point.password,
            },
            {
              headers: {
                Authorization:
                  "Bearer " +
                  JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
              },
            }
          )
          .then(function (response) {
            console.log(response.data);
            if (response.data.status_code == 200) {
              showSuccessToast(point.$t(response.data.message));
              setTimeout(() => {
                location.reload();
              }, 1000);
            } else {
              point.loader = false;
              showFailToast(point.$t(response.data.message));
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              localStorage.removeItem("china_app_access_token");
              location.reload();
            }
          });
      } else {
        showFailToast(point.$t("missing_fields"));
      }
    },

    getResult() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/luckydraw",
          { enduser_id: enduser_id, spin_type: 2 },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          let result = response.data.data;
          point.winded_prizes = result;
          console.log(result);

          if (result == null) {
            point.router.push("/spinner");
          } else {
            point.prizeId = result.id;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },
    

    getSpinPrizes() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/luckydraw/product",
          { enduser_id: enduser_id, spin_type: 2 },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          point.spin_chance = response.data.data.chances;
          point.balance = response.data.data.user_balance;
          point.spin_sell_price = response.data.data.spin_price;
          let prizes = response.data.data.products;

          console.log(response.data.data);
          let remainder = 0;
          let probability = Math.floor(100 / prizes.length);
          prizes.map((prize, i) => {
            remainder = 100 % prizes.length;
            let add_remainder = i == 0 ? remainder : 0;
            let add_probability = add_remainder + probability;

            point.prizesCanvas.push({
              id: prize.id,
              name: prize.name,
              probability: Number(add_probability),
              bgColor: i % 2 != 0 ? "#EBE7E6" : "#ffffff",
              color: "#E25215",
              value: prize.name,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },
  },
  mounted() {
    this.setWheelSize();
  },
  created() {
    var elements = document.querySelectorAll(".mobile-bottom-nav");
    elements.forEach(function (element) {
      element.classList.add("d-none");
    });
    this.getSpinPrizes();
  },
};
</script>

<style>
#topParent {
  margin-top: 0px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  /* border : 2px solid green; */
  /* border-radius : 50% */
}
canvas {
  width: 300px !important;
  height: 300px !important;
}
.indicator {
  position: absolute;
  top: 20px; /* Adjusted position */
  left: 50%;
  transform: translateX(-80%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid red; /* Color of the indicator */
  z-index: 10; /* Ensure it is on top of the wheel */
}
.fw-btn {
  margin-top: 10rem;
}
#spin_bg_w {
  background-image: url("../assets/spinner/wsbg.png");
  background-color: #e25215;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-position-y: -20%; */
}
.fw-btn__btn {
  /* background-color: #cccccc; */
  /* width : 40px !important; */
  /* height : 40px !important; */
  position: relative;
  left: 16%;
  top: 1.5rem;
  font-weight: bold;
}
.fw-btn {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e25215;
  color: white;
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  z-index: 20;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  /* box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px; */
}
</style>
