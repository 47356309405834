<template>
    
    <div class="">
            <div >
                <div class="p-4 pb-2" style="background: linear-gradient(90deg, rgba(61,172,120,.2) 0%, rgba(255,255,255,1) 100%);">
                <div class=" rounded-4">
                    <div class="">
                        <h5 class="text-center ">{{$t('regulatory_unit')}}</h5>
                    </div>
                </div>
            </div>
            <div class="divider">
               <div class="w-100" style="border: 1px solid #3DAC78"></div>
            </div>
            
            </div>
            
        </div>

       


        <div class="p-2 pb-1">
            <div class="">
                
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/1.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/2.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/3.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/4.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/5.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/6.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/7.png" class="img-fluid w-100">
                </div>
                <div class="d-flex justify-content-between" style="padding-bottom: 5px">
                    <img src="../assets/outer/8.png" class="img-fluid w-100">
                </div>
                
            <!-- <button class="btn btn-outline-success w-100  mb-5" @click="signout()">{{$t('logout')}}</button> -->
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            

        }
    },
    methods : {
        
    },

    created(){
        
    }
}
</script>

<style>


</style>