<template>
  <div id="">
    <div class="row fixed-top">
      <div class="" style="background-color: rgba(0, 0, 0, 0); z-index: -10">
        <router-link to="/spinner">
          <fa
            class="text-white"
            style="position: absolute; top: 20px; left: 20px"
            :icon="['fas', 'arrow-left']"
          ></fa>
        </router-link>
        <p class="text-white text-center pt-3">Prize Announcement</p>
        <hr class="mt-0 text-white">
      </div>
    </div>

   <div  style="margin-top : 4rem" class="p-3 text-white">
    <div v-for="(reward , i) in rewards" :key="i">
      <div v-html="reward.description"></div>
    </div>
   </div>

    
  </div>
</template>

<script>
// import { showSuccessToast, showFailToast } from "vant";

export default {
  setup() {
    
  },

  components: {
  },
  data() {
    return {
      rewards : null
    };
  },
  methods: {
    getSpinPrizes() {
      let point = this;
      let enduser_id = this.$store.state.users.id;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/luckydraw/product",
          { enduser_id: enduser_id, spin_type: 1 },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
            },
          }
        )
        .then(function (response) {
          
          point.rewards = response.data.data.rewards;

        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.removeItem("china_app_access_token");
            location.reload();
          }
        });
    },
  },
  mounted() {
  },
  created() {
    var elements = document.querySelectorAll(".mobile-bottom-nav");
    elements.forEach(function (element) {
      element.classList.add("d-none");
    });
    this.getSpinPrizes();

  },
};
</script>

<style>
#topParent {
  margin-top: 0px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
