<template>

    <div>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <Iconify class="text-white fw-bold" style="position : absolute ; top : 32px ; left : 30px; font-size: 19px !important; color: #3dac78 !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>

            <div class="fs-5 fw-semibold my-5 mt-4" style="color: #3dac78 !important;">
                {{$t('invite')}}
            </div>
        </div>
        
        <div class="m-5 mb-4 mx-4 text-white" style="background-color: #3dac78 !important; border-radius: 25px !important; height: 375px !important;">
            <div id="inviteCodeSection">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <div>
                        <img class="border" v-if="this.$store.state.users.profile != null" style="display: inline-block; width: 90px;height: 90px;border-radius: 50%;" width="" :src="image_path+'uploads/enduser_profile/'+this.$store.state.users.profile">
                        <img class="border" v-else style="display: inline-block; width: 90px;height: 90px;border-radius: 50%;" width="" src='../assets/mine/pp.jpg'>
                        <div class="my-3 mb-2 fw-semibold">
                            {{ this.$store.state.users.member_name }}
                        </div>
                        <div class="">
                            {{ this.$store.state.users.phone }}
                        </div>
                        
                    </div>
                </div>

                <div class="mt-3 d-flex flex-column justify-content-center align-items-center">
                    <div class="card card-body p-3" style="width: 170px; height: 170px;">
                        <qrcode-vue :value="qrDownload" :size="size" level="H" class="rounded img-fluid" />
                    </div>
                </div>

                <div class="mt-3 d-flex flex-column justify-content-center align-items-center text-center">
                    <div>
                        {{$t('invitation_code_is')}} <span class="fw-semibold">{{ this.$store.state.users.invite_code }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-5 d-flex justify-content-center align-items-center">
            <button class="btn text-white" @click="copy()" style="background-color: #3dac78; width: 80% !important; height: 40px">
                {{$t('click_to_copy')}} <Iconify icon="ant-design:copy-outlined" />
            </button>
        </div>

    </div>
    <!-- <div class="inviteBg">
        <div class="row fixed-top">
        <div class="" style="background-color : black">
            <router-link to="/">
                <fa class="text-white" style="position : absolute ; top : 20px ; left : 20px" :icon="['fas', 'arrow-left']"></fa>
            </router-link>
            
        </div>
       
    </div>

        <div class="p-4" id="qrcard">
            <div class="card" style="background-color : rgba(255, 255 , 255 , .15);
                backdrop-filter : blur(5px)">
            <div class="card-header" style="background-color : rgba(0,0,0,.4)">
                <p class="text-center fw-bold text-white">{{$t('invitation_code')}}</p>
                <h3 class="text-center text-white" style="color : #773DF5">{{ this.$store.state.users.invite_code }}</h3>
                <p class="text-center text-white" style="font-size : 14px">{{$t('invite_code_desc')}}</p>
            </div>
            <div class="card-body text-center">
                <qrcode-vue :value="qrDownload" :size="size" level="H" class="rounded img-thumbnail" />
                <div>
                    <button @click="copy()" class="btn btn-light  btn-sm mt-3 btn-pill rounded-pill px-3"><fa class="me-2" style="" :icon="['far', 'copy']"></fa> {{$t('click_to_copy')}} </button>
                </div>

            </div>
        </div>
        </div>
    </div> -->
</template>

<script>

import { showSuccessToast } from 'vant';
import QrcodeVue from 'qrcode.vue'
export default {
    data(){
        return {
            value:process.env.VUE_APP_FRONTEND_BASE_URL+'register/'+this.$store.state.users.invite_code,
            qrDownload:'https://backend.zxrhxio.asia/app/download',
            size: 200,
            image_path : process.env.VUE_APP_BASE_IMAGE_URL,
        }
    },
    methods : {
        copy(){
           
            // console.log(this.value)
            // const message = {
            //     name: 'clipboard',
            //     value: this.value
            // };

            const Toaster = {
                postMessage: function(text) {
                // Send message to Flutter
                const message = {
                    channel: 'Toaster',
                    value: text
                };
                window.postMessage(JSON.stringify(message), '*');
                }
            };

            Toaster.postMessage(this.value);

            navigator.clipboard.writeText(this.value);
            showSuccessToast(this.$t('copied'))
            // this.$notify({ text: '复制成功',type: "success",});
        }
    },
    components: {
      QrcodeVue,
    },
}
</script>

<style>
#inviteCodeSection {
    position: relative;
    top: -50px;
}
</style>