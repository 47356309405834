<template>
    <div>
        <div class="px-4 mb-3" style="padding-top: 0.8rem !important;">
            <div>
                <Iconify class="" style="position : absolute ; top : 24px ; left : 30px; font-size: 18px !important; color: #3dac78;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>
                <div class="text-center py-3 pt-2 fw-semibold" style="font-size: 18px; color: #3dac78;">
                    {{$t('daily_checkin')}}
                </div>
            </div>
            
        </div>

        <div class="mx-4 mt-3" >
            <div class="card shadow-sm" style="border-color: #3dac78 !important;">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center py-2">
                        <div>
                            <span class="fw-semibold" style="font-size: 17px;">{{$t('total_earn')}}:</span> {{ this.$store.state.users.points }} (¥)
                        </div>
                        <div>
                            <!-- <div v-if="this.$store.state.users.points < 100" class="border px-1 rounded-2 text-white" style="background-color: #eee; font-size: 15px; padding: 2px 8px !important; font-weight: 600;">
                                {{$t('claim')}}
                            </div> -->

                            <!-- <div v-else @click="claim()" class="border px-1 rounded-2 text-white" style="background-color: #3dac78; font-size: 14px; padding: 2px 8px !important; font-weight: 600;">
                                {{$t('claim')}}
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 border-bottom" style="background-color: #b6b6b6;"></div>
        </div>

        <div v-if="!isCheckedIn" class="px-4 mt-3">
            <div class="d-flex align-items-center">
                <div class="fw-semibold d-flex flex-column justify-content-center align-items-center ">
                    <div>{{today.month}}</div>
                    <div>{{today.day}}</div>
                </div>
                <div class="" style="margin: 0rem 2.2rem;">
                    <div style="width: 10px; height: 10px; background-color: #3dac78; border-radius: 50%;"></div>
                </div>
                <div class="flex-grow-1">
                    <div class="card" style="border-color: #3dac78 !important; border-style: dashed !important;">
                        <div class="card-body d-flex justify-content-between align-items-center py-3 px-4">
                            <div class="fw-semibold d-flex flex-column justify-content-center align-items-center">
                                <div class="" style="font-size: 14px;">{{$t('get')}}</div> 
                                <div class="" style="font-size: 14px;">?? (¥)</div> 
                            </div>
                            <div>
                                <div @click="checkIn()" class="border px-1 rounded-2 text-white" style="background-color: #3dac78; font-size: 14px; padding: 2px 8px !important; font-weight: 600;">
                                    {{$t('check_in')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="mt-3 border-bottom" style="background-color: #b6b6b6;" /> -->

        <div class="mt-4 px-4">
            <div v-for="(day, index) in total_days" :key="index" class="d-flex align-items-center mb-4">
                <div class="fw-semibold d-flex flex-column justify-content-center align-items-center ">
                    <div>{{day.month}}</div>
                    <div>{{day.day}}</div>
                </div>
                <div class="" style="margin: 0rem 2.2rem;">
                    <div style="width: 10px; height: 10px; background-color: #e7e7e7; border-radius: 50%;"></div>
                </div>
                <div class="flex-grow-1">
                    <div class="card" style="border-color: #3dac78 !important; border-style: dashed !important;">
                        <div class="card-body d-flex justify-content-between align-items-center py-3 px-4">
                            <div class="fw-semibold d-flex flex-column justify-content-center align-items-center">
                                <div class="" style="font-size: 14px;">{{$t('get')}}</div> 
                                <div class="" style="font-size: 14px;">{{day.amount}} (¥)</div> 
                            </div>
                            <div>
                                <div class="border px-1 rounded-2 text-white" style="background-color: #e7e7e7; font-size: 14px; padding: 2px 8px !important; font-weight: 600;">
                                    {{$t('check_in')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
 <script>
import {showToast } from 'vant';
import moment from 'moment';

 export default {
     data(){
        return {
            total_days : [],
            today: {month: '', day: '', date: ''},
            isCheckedIn: false,
            loader : false
        }
     },

     methods : {
        // claim(){
        //     // let point = this;
        //     let enduser_id = this.$store.state.users.id;
        //     this.axios
        //         .post(
        //             process.env.VUE_APP_BASE_URL + "/finance/daily/checkin/claim",
        //             {enduser_id},
        //             {
        //             headers: {
        //                 Authorization:
        //                 "Bearer " +
        //                 JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
        //             },
        //             }
        //         )
        //         .then(function (response) {
        //             // point.total_days = response.data.data.days
        //             console.log(response.data)
        //             showToast({
        //                 message: response.data.message,
        //                 wordBreak: 'break-all',
        //             });

        //             setTimeout(() => {
        //                 location.reload();
        //             }, 2000);
        //             // showToast({
        //             //     message: point.$t(response.data.message),
        //             //     wordBreak: 'break-all',
        //             // });
        //             // point.members = response.data[0];
        //             // point.member_count = response.data[1];
        //         })
        //         .catch((error) => {
        //             console.log(error.response)
        //             if (error.response.status == 401) {
        //             // localStorage.removeItem("china_app_access_token");
        //             // location.reload();
        //             }
        //         });
        // },
        formatDate(date = null) {
            let makeDate = date ? moment(date) : moment();
            const monthFormatted = makeDate.format("MMM");
            const dayFormatted = makeDate.format("DD");
            const monthDayFormatted = `${monthFormatted} ${dayFormatted}`;
            return {"month" : monthFormatted, "day" : dayFormatted, "date" : monthDayFormatted}
        },
        checkIn(){
            // let point = this;
            let enduser_id = this.$store.state.users.id;
            this.axios
            .post(
                process.env.VUE_APP_BASE_URL + "/finance/daily/checkin",
                {enduser_id},
                {
                headers: {
                    Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
                },
                }
            )
            .then(function (response) {
                // point.total_days = response.data.data.days
                console.log(response.data)
                showToast({
                    message: response.data.message,
                    wordBreak: 'break-all',
                });

                setTimeout(() => {
                    location.reload();
                }, 2000);
                // showToast({
                //     message: point.$t(response.data.message),
                //     wordBreak: 'break-all',
                // });
                // point.members = response.data[0];
                // point.member_count = response.data[1];
            })
            .catch((error) => {
                console.log(error.response)
                if (error.response.status == 401) {
                // localStorage.removeItem("china_app_access_token");
                // location.reload();
                }
            });
        }
     },

     created(){
        // Jquery('.mobile-bottom-nav').addClass('d-none')
        var elements = document.querySelectorAll(".mobile-bottom-nav");
        elements.forEach(function(element) {
            element.classList.add("d-none");
        });
        let point = this;
        let enduser_id = this.$store.state.users.id;
        this.axios
        .post(
            process.env.VUE_APP_BASE_URL + "/finance/daily/checkin/get",
            { enduser_id },
            {
                headers: {
                    Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("china_app_access_token")).access_token,
                },
            }
        )
        .then(function (response) {
            // console.log(response.data)
            // point.total_days = response.data;
            const getToday = point.formatDate();
            point.today = getToday;
            point.total_days = response.data.map((day) => {
                let getDate = point.formatDate(day.checkinDate);
                
                return {
                    ...getDate,
                    'amount': day.amount
                }
            })

            if (point.total_days.some(item => item.date === getToday.date)) {
                point.isCheckedIn = true;
            }
            // point.members = response.data[0];
            // point.member_count = response.data[1];
        })
        // .catch((error) => {
        //     if (error.response.status == 401) {
        //     localStorage.removeItem("china_app_access_token");
        //     location.reload();
        //     }
        // });
     }
 }
 </script>
 
 <style>
     
 </style>