import axios from 'axios'
import InvitePage from '@/views/InvitePage.vue'
import HomePage from '@/views/HomePage.vue'
import MinePage from '@/views/MinePage.vue'
import RechargePage from '@/views/RechargePage.vue'
import InvestmentPage from '@/views/InvestmentPage.vue'
import TransactionRecordPage from '@/views/TransactionRecord.vue'
import MyTeam from '@/views/MyTeam.vue'
import WalletPage from '@/views/WalletPage.vue'
import VerifyPage from '@/views/VerifyPage.vue'
import BankPage from '@/views/BankPage.vue'
import SettingPage from '@/views/SettingPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import NewsDetail from '@/views/newsDetail.vue'
import NewsAll from '@/views/newsAll.vue'
import SplashScreen from '@/views/SplashScreen.vue'
import DailyCheckIn from '@/views/DailyCheckIn.vue'
import InvestmentDetailPage from '@/views/InvestmentDetailPage.vue'
import WithdrawPage from '@/views/WithdrawPage.vue'
import MaintainanceView from '@/views/MaintainanceView.vue'
import { createRouter , createWebHistory } from 'vue-router'
import store from '../vuex/store.js'
// import { useNotification } from "@kyvg/vue3-notification";
import { showFailToast } from 'vant';
// import InvestmentDataPage from '@/views/InvestmentDataPage.vue'
import InvestmentHistory from '@/views/InvestmentHistory.vue'
import SpinWheelPage from '@/views/SpinWheelPage.vue'
import WinSpinWheelPage from '@/views/WinSpinWheelPage.vue'
import SpinRules from '@/views/SpinRules.vue'
import SpinPrizeAnnouncement from '@/views/SpinPrizeAnnouncement.vue'
import SpinHistory from '@/views/SpinHistory.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import ContactUsPage from '@/views/ContactUsPage.vue'
import OuterLink from '@/views/OuterLink.vue'
import i18n from '../i18n.js'

// const { notify}  = useNotification()

const router = createRouter({
    history : createWebHistory(),
    mode : 'history',
    routes : [
        {path : '/' , name : 'home', component :   HomePage},
        {path : '/maintain' , name : 'maintain', component :   MaintainanceView},
        {path : '/login' , name : 'login', component :   LoginPage},
        {path : '/register/:code?' , name : 'register', component :   RegisterPage},
        {path : '/invite' , name : 'invite' , component :   InvitePage},
        {path : '/mine' , name : 'mine' , component :   MinePage},
        {path : '/recharge' , name : 'recharge' , component :   RechargePage},
        {path : '/outer/link' , name : 'outerLink' , component :   OuterLink},
        {
            path : '/investment' , name : 'investment' , component :   InvestmentPage,
            beforeEnter : (to , from , next) => {
                let enduser_id = store.state.users.id
                axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
                {'enduser_id' : enduser_id},
                
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    console.log(response.data)
                    if(response.data.status_code == 200){
                        next()
                    }else{
                        showFailToast(i18n.global.t('verify_first'))
                         // next()

                        // notify({
                        //     text: "请先验证",
                        //     type: "error",
                        //   });
                    }
                })
                
            }
        },
        {path : '/investment/detail/:product_id' , name : 'investment_detail' , component :   InvestmentDetailPage},
        // {path : '/investment/data' , name : 'investment_data' , component :   InvestmentDataPage},
        {path : '/transaction/record' , name : 'transaction_record' , component :   TransactionRecordPage},
        {path : '/myteam' , name : 'myteam' , component :   MyTeam,
            beforeEnter : (to , from , next) => {
                let enduser_id = store.state.users.id
                axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
                {'enduser_id' : enduser_id},
                
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    // console.log(response.data)
                    if(response.data.status_code == 200){
                        next()
                    }else{
                        showFailToast(i18n.global.t('verify_first'))

                        // next()

                        // notify({
                        //     text: "请先验证",
                        //     type: "error",
                        //   });
                    }
                })
                
            }
        },
        {path : '/wallet' , name : 'wallet' , component :   WalletPage,
        beforeEnter : (to , from , next) => {

                let enduser_id = store.state.users.id
                axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
                {'enduser_id' : enduser_id},
                
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    
                    if(response.data.status_code == 200){
                        next()
                    }else{
                        showFailToast(i18n.global.t('verify_first'))
                        // notify({
                        //     text: "Please verify first",
                        //     type: "error",
                        //   });
                    }
                })
                
            }
        },
        {
            path : '/withdraw/:wallet_id' , name : 'withdraw' , component :   WithdrawPage,
            beforeEnter : (to , from , next) => {
                let enduser_id = store.state.users.id
                axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
                {'enduser_id' : enduser_id},
                
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    
                    if(response.data.status_code == 200){
                        next()
                    }else{
                        showFailToast(i18n.global.t('verify_first'))
                    }
                })
                
            }
        },
        {path : '/verified' , name : 'verified' , component :   VerifyPage},
        {path : '/aboutus' , name : 'aboutus' , component :   AboutUsPage},
        {path : '/contactus' , name : 'contactus' , component :   ContactUsPage},
        {path : '/bank' , name : 'bank' , component :   BankPage},
        {path : '/usersetting' , name : 'usersetting' , component :   SettingPage},
        {path : '/news/detail' , name : 'newsDetail' , component :   NewsDetail},
        {path : '/news/all' , name : 'newsAll' , component :   NewsAll},
        {path : '/splash/screen' , name : 'splashScreen' , component :   SplashScreen},
        {path : '/daily/checkin' , name : 'DailyCheckIn' , component :   DailyCheckIn},
        {path : '/investment/history/:group_id?' , name : 'InvestmentHistory' , component :   InvestmentHistory},
        {path : '/spinner' , name : 'SpinWheelPage' , component :   SpinWheelPage},
        {path : '/win-spinner' , name : 'WinSpinWheelPage' , component :   WinSpinWheelPage},
        {path : '/spinner/rules' , name : 'SpinnerRules' , component :   SpinRules},
        {path : '/spinner/prizes/announcement' , name : 'SpinPrizeAnnouncement' , component :   SpinPrizeAnnouncement},
        {path : '/spinner/history' , name : 'spinHistory' , component : SpinHistory},
    ]
})

router.beforeEach(async (to) => {
    if(
        to.name == 'invite' || to.name == 'transaction_record'  || to.name == 'verified'
        || to.name == 'bank' || to.name == 'usersetting' || to.name == 'investment' || to.name == 'newsDetail' || to.name == 'newsAll' 
        || to.name == 'DailyCheckIn' || to.name == 'investment_data'  || to.name == 'InvestmentHistory'
        || to.name == 'SpinWheelPage' || to.name == 'WinSpinWheelPage' || to.name == 'SpinPrizeAnnouncement' || to.name == 'SpinnerRules' || to.name == 'spinHistory' 
        || to.name == 'aboutus' || to.name == 'outerLink'
    ){
        let auth = JSON.parse(localStorage.getItem('china_app_access_token'))
        //  return '/maintain';

        if(auth){
            console.log(auth)

            store.commit('storeUserInfo' , auth)
            return true
        } else {
            return '/splash/screen';
        }
    }
    if(to.name == 'home' || to.name == 'mine' || to.name == 'wallet' || to.name == 'myteam' || to.name == 'investment_detail' || to.name == 'withdraw' || to.name == 'recharge' ) {
        let auth = JSON.parse(localStorage.getItem('china_app_access_token'))
        //  return '/maintain';
            

        if(auth){
            // store.commit('storeUserInfo' , auth)

            await axios.post(process.env.VUE_APP_BASE_URL+'/get/user/balance',
                {'enduser_id' : auth.id},
                {
                    headers: {
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                    }
                }
                ).then(function(response){
                    console.log('response')
                    store.commit('storeUserInfo' , response.data)
                }).catch(() => {
                    // if (error.response?.status) {
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    // }
                })
            return true
        } else {
            return '/splash/screen';
        }
    }
})

export default router;