<template>
    <div style="margin-bottom: 8rem;">
        <div class="d-flex justify-content-between align-items-start px-4 py-3 shadow-sm" style="color: #3dac78;">
            <router-link to="/investment">
                <Iconify icon="weui:back-outlined" style="font-size: calc(1.2rem + 1.5vw) !important; color: #3dac78;" class="fw-bold" />
            </router-link>

            <div class="fs-6 fw-semibold" style="width: 55%">
                {{$t('detail')}}
            </div>
        </div>
        <div class="pb-3 px-4" style="padding-bottom : 2rem" v-if="product">
            

            <div class="my-4 shadow" >
                <img :src="image_path+'storage/images/'+ product.picture" alt="" class="img-fluid" style="width: 100%; height: 180px">
            </div>
        
            <div class="my-4 d-flex justify-content-between align-items-end" style="height: 100px">
                <div class="d-flex flex-column align-content-between align-items-start" style="width: 65%;">
                    <div class="fs-5 fw-semibold" style="margin-bottom: 28px">{{ product.name }}</div>
                    <div class="fw-semibold" style="color: #3dac78">
                        <span><Iconify icon="icons8:price-tag" style="font-size: calc(0.9rem + 1.5vw) !important;" class="me-1" /></span>
                        <span>¥ {{product.price}}</span>
                        <div></div>
                    </div>
                </div>

                
                <div class="d-flex flex-column align-content-between align-items-end">
                    <div class="fw-semibold mb-auto" style="font-size: 13px"> {{$t('pay_back')}}</div>
                    <div class="fw-semibold my-1" style="color: #3dac78">
                        <div class="fw-semibold text-center fs-4" v-if="product.isInterestFixedProduct == 0">{{product.static_income}} %</div>  
                        <div class="fw-semibold text-center" v-else>¥ {{Number(product.price) + Number(product.fixedInterest)}}</div>  
                        
                    </div>
                    <div>
                        <div class="" style="font-size: 13px;" v-if="product.isInterestFixedProduct == 0">{{$t('every')}} {{product.pay_day_interval}} {{$t('days')}} </div>
                        <div class="" style="font-size: 13px;" v-else>{{$t('after')}} {{product.pay_day_interval}} {{$t('one_time_days')}}</div>
                    </div>
                </div>
                    

            </div>

            <div class="mt-4">
                <div class="fs-6 fw-semibold mb-3">{{$t('description')}}</div>
                <div v-html="product.description"></div>
            </div>

            
        </div>

        <div :id="show == false ? 'checkoutBar' : 'checkoutBarHide'" class="p-4 py-4" style="width: 100%; background-color: white;">
            <div class="d-flex align-items-center">
                <div class="pe-3" style="width: 30%">
                    <div class="d-flex justify-content-between border rounded-2 px-2 py-1" style="border-color: #3dac78 !important;">
                        <div><Iconify icon="lsicon:minus-filled" @click="qty > 1 ? qty-- : ''" /></div>
                        <div class="border border-top-0 border-bottom-0 text-center" style="width: 40%; border-color: #3dac78 !important;">{{ qty }}</div>
                        <div><Iconify icon="gridicons:add" @click="qty++" /></div>
                    </div>
                </div>
                <button @click="show = true" class="btn btn-success" style="width: 70%; background-color: #3dac78 !important; border-color: #3dac78 !important;">{{$t('buy')}}</button>
            </div>
        </div>

        <van-popup
            v-if="product"
            class=""
            v-model:show="show"
            position="bottom"
            :style="{ height: '70%' , 'background-color' : 'white !important', 'border-radius': '50px 50px 0px 0px' }"
        >
            <div class="mt-3 d-flex justify-content-center">
                <div class="rounded" style="width: 30%; height: 4px; background-color: #3dac78;"></div>
            </div>

            <div class="mt-2 p-4">
                <div class="fs-5 mb-3 text-dark fw-semibold">
                    {{$t('summary')}}
                </div>

                <div class="pb-2 border-bottom" style="border-bottom-style: dashed !important;">
                    <!-- <div class="fs-6 text-dark fw-medium">Product</div> -->
                    <div class="d-flex justify-content-start align-items-start my-3">
                        <div class="me-4" style="width: 30%">
                            <img :src="image_path+'storage/images/'+ product.picture" alt="" class="img-fluid" style="width: 100%; height: 90px;">
                        </div>

                        <div>
                            <div class="fw-medium text-dark fs-6 mb-2">{{product.name}}</div>
                            <div class="fw-semibold" style="color: #3dac78">
                                <span><Iconify icon="icons8:price-tag" style="font-size: calc(0.9rem + 1.5vw) !important;" class="me-1" /></span>
                                <span>¥ {{product.price}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="py-3 border-bottom" style="border-bottom-style: dashed !important;">
                    
                    <div class="d-flex justify-content-between">
                        <div class="fw-medium fs-6">{{$t('quantity')}} </div> 
                        <div class="fw-semibold" style="color: #3dac78;">{{qty}}</div> 
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                        <div class="fw-medium fs-6">{{$t('total')}} </div> 
                        <div class="fw-semibold" style="color: #3dac78;">¥ {{qty * product.price}}</div> 
                    </div>
                </div>

                <div class="py-3 border-bottom" style="border-bottom-style: dashed !important;">
                    <div class="d-flex justify-content-between">
                        <div class="fw-medium fs-6">{{$t('account_balance')}} </div> 
                        <div class="fw-semibold" style="color: #3dac78;">¥ {{balance}}</div> 
                    </div>
                </div>
                <div class="my-3">
                    <label class="fw-semibold mb-1">{{$t('fund_password')}}</label>
                    <div class="input-group pt-2">
                        <input type="password" v-model="password" class="form-control" :placeholder="$t('fund_password')" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>

                <!-- <div class="my-3">
                    <label class="fw-semibold mb-1">Quantity</label>

                    <div class="input-group pt-2">
                        <span class="input-group-text  text-white" style="background-color : #3dac78 ; border : 1px solid #3dac78" @click="qty > 1 ? qty-- : ''">-</span>
                        <input type="text" v-model="qty" class="form-control text-center" aria-label="Amount (to the nearest dollar)">
                        <span class="input-group-text  text-white" style="background-color : #3dac78 ; border : 1px solid #3dac78" @click="qty++">+</span>
                    </div>
                </div> -->
                <button :disabled="loader ? true : false" class="btn col-12 text-white mt-3" @click="choose()" style="background-color: #3dac78;">{{$t('submit')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { ref } from 'vue';
import { showSuccessToast , showFailToast} from 'vant';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const show = ref(false);
        return {
        show
        };
    },
    data (){
        return {
           image_path : process.env.VUE_APP_BASE_IMAGE_URL,
           product : null,
           qty : 1,
           product_id : '',
           password : null,
           loader : false,
           balance: 0,
           enduser_id: this.$store.state.users.id,
        }
    },

    methods : {
        choose(){
            let point = this;
            // let enduser_id = this.$store.state.users.id

            if(point.qty != null && point.password != null){
                point.loader = true
                this.axios.post(process.env.VUE_APP_BASE_URL+'/investment/product',
                {'enduser_id' : point.enduser_id , 'qty' : point.qty , 'investment_product_id' : point.product_id , 'password' : point.password},
                    {
                        headers: {
                            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                        }
                    }
                ).then(function(response){
                    // console.log(response.data)
                    if(response.data.status_code == 200){
                        showSuccessToast(point.$t(response.data.message))
                        setTimeout(()=>{
                            location.reload()
                        },1000)
                    }else{
                        point.loader = false
                        showFailToast(point.$t(response.data.message))

                    }
                    
                }).catch((error)=>{
                    // console.log(error)
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })
            }else{
                showFailToast(point.$t('missing_fields'))
            }
        },
        encrypt(){
            let hmac_token =  CryptoJS.HmacSHA256('asdf', 'UlTR0n@2o2A')
            const hashHex = hmac_token.toString(CryptoJS.enc.Hex);
            return hashHex
        }
    },
    
    created(){
        this.product_id = this.$route.params.product_id;
        let point = this;
        
        this.axios.post(process.env.VUE_APP_BASE_URL+'/investment/product/detail',
                {'enduser_id' : point.enduser_id , 'product_id' : point.product_id},
                    {
                        headers: {
                            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                        }
                    }
                ).then(function(response){
                    // console.log(response.data)
                    if(response.data.status_code == 200){
                        console.log(response.data.data)
                        point.balance = response.data.data.user_balance.balance;
                        point.product = response.data.data.product;

                    }
                    
                }).catch((error)=>{
                    // console.log(error)
                    if(error.response.status == 401){
                        localStorage.removeItem('china_app_access_token')
                        location.reload()
                    }
                })

    }
}
</script>

<style>

#checkoutBar {
    position: fixed;
    bottom: 0;
    z-index: 9999999999;
}
#checkoutBarHide {
    display: hidden;
}
</style>