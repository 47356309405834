<template>
  <nav-bar></nav-bar>

  <!-- <van-pull-refresh 
    v-model="isRefreshing"
    pulling-text="Pull down to refresh the page"
    loosing-text="Release to refresh"
    loading-text="Refreshing..."
    @refresh="onRefresh"
    class="full-page-refresh"
  > -->
    <div style="padding-bottom: 2rem !important;">
      <router-view/>
      <notifications position="top right" />
    </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import NavBar from '@/views/NavBar.vue'
export default {
  name: 'App',
  components : {
    'nav-bar' : NavBar
  },

  data() {
    return {
      isRefreshing: false,
    };
  },

  methods: {
    onRefresh() {
      setTimeout(() => {
        this.isRefreshing = false;
        window.location.reload();
      }, 1000);
    },
  },


  mounted(){
    document.title = 'Brasilagro'; // Set your site name

  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family:  Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */

}

.full-page-refresh {
  height: 100vh !important;
  overflow: scroll !important;
}


label{
  font-family:  Arial;
  font-size:  14px;
}

.lds-dual-ring {
  display: inline-block;
  width: 25px;
  height: 25px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  /* margin: 8px; */
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  margin-left : 2rem;
  margin-bottom : 2rem;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#app {
  touch-action: manipulation;
}
</style>
